import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

const JSONEditor = ({ survey, onSurveyUpdate }) => {
    const [jsonString, setJsonString] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        // Remove "placeholder" and "image" properties from the survey object
        const cleanedSurvey = JSON.parse(JSON.stringify(survey));
        cleanedSurvey.elements = cleanedSurvey.elements.map(element => {
            const { placeholder, image, ...rest } = element;
            return rest;
        });

        setJsonString(JSON.stringify(cleanedSurvey, null, 2));
    }, [survey]);

    const handleChange = (newValue) => {
        setJsonString(newValue);
        try {
            const parsedJson = JSON.parse(newValue);
            setError(null);
            onSurveyUpdate(parsedJson);
        } catch (e) {
            setError('Invalid JSON: ' + e.message);
        }
    };

    return (
        <div className="w-full">
            <AceEditor
                mode="json"
                theme="github"
                onChange={handleChange}
                name="json-editor"
                value={jsonString}
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                    useWorker: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}
                style={{ width: '100%', height: '500px' }}
            />
            {error && <div className="text-red-500 mt-2">{error}</div>}
        </div>
    );
};

export default JSONEditor;