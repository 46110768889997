import React, { useState, useRef, useEffect, useCallback, useContext, Suspense, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MdShortText,
  MdTextFields,
  MdArrowDropDownCircle,
  MdExpandMore,
  MdCheckBox,
  MdRadioButtonChecked,
  MdStarRate,
  MdImage,
  MdDragIndicator,
  MdGeneratingTokens,
} from 'react-icons/md';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import QuestionItem from './question';
import SurveyHeader from './SurveyHeader';
import LogicBuilderPage from './Logic/LogicBuilderPage';
import PreviewPage from './preview';
import JSONEditor from './JSONEditor';
import {
  FiPlusCircle,
  FiGrid,
  FiEye,
  FiCode,
  FiSettings,
  FiMenu, FiX,
  FiArrowRight,
  FiArrowLeft,
  FiLoader,
} from 'react-icons/fi';
import AIbot from '../AIbot';
import { Sparkle, } from 'lucide-react';
import { sendMsgToOpenAI } from '../../Utils/chatGPT';
import { useAuth } from '../../api/AuthContext';
import axiosApi from '../../api/axiosApi';
import SubscribeModal from '../SubcribeModel';

const questionTypes = [
  { id: 'text', name: 'Short Answer', icon: <MdShortText /> },
  { id: 'comment', name: 'Paragraph', icon: <MdTextFields /> },
  { id: 'dropdown', name: 'Dropdown', icon: <MdArrowDropDownCircle /> },
  { id: 'tagbox', name: 'Multi-Select', icon: <MdExpandMore /> },
  { id: 'checkbox', name: 'Checkboxes', icon: <MdCheckBox /> },
  { id: 'boolean', name: 'Yes/No', icon: <MdRadioButtonChecked /> },
  { id: 'rating', name: 'Rating Scale', icon: <MdStarRate /> },
  { id: 'radiogroup', name: 'Radio Group', icon: <MdRadioButtonChecked /> },
  { id: 'ranking', name: 'Ranking', icon: <MdDragIndicator /> },
];

export default function RRSurvey({ isEditMode = false }) {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [jsonEditorEnabled, setJsonEditorEnabled] = useState(false);
  const [survey, setSurvey] = useState(() => {
    if (isEditMode) {
      return {
        title: "Your Survey Title",
        description: "Your Survey Description",
        logo: null,
        logoWidth: "100px",
        logoFit: "fill",
        logoPosition: "right",
        elements: []
      };
    }

    // Try to load survey from local storage
    const savedSurvey = localStorage.getItem('currentSurvey');
    return savedSurvey ? JSON.parse(savedSurvey) : {
      title: "Your Survey Title",
      description: "Your Survey Description",
      logo: null,
      logoWidth: "100px",
      logoFit: "fill",
      logoPosition: "right",
      elements: []
    };
  });

  const saveSurveyToLocalStorage = useCallback(() => {
    if (!isEditMode) {
      localStorage.setItem('currentSurvey', JSON.stringify(survey));
    }
  }, [survey, isEditMode]);

  useEffect(() => {
    saveSurveyToLocalStorage();
  }, [survey, saveSurveyToLocalStorage]);

  const finalQuestionCount = useMemo(() => survey.elements.length, [survey.elements]);

  const [activeTab, setActiveTab] = useState('designer');
  const [sidebarOpen, setSidebarOpen] = useState(() => {
    return window.innerWidth >= 768;
  });

  const lastQuestionRef = useRef(null);
  const prevQuestionsLength = useRef(survey.elements.length);

  useEffect(() => {
    if (survey.elements.length > prevQuestionsLength.current && lastQuestionRef.current) {
      lastQuestionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    prevQuestionsLength.current = survey.elements.length;

  }, [survey]);

  const handleResize = useCallback(() => {
    setSidebarOpen(window.innerWidth >= 768);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

  const addQuestion = (type, dragged = false) => {
    if (survey.elements.length >= max_question) {
      setShowUpgradeDialog(true);
      return;
    }

    const newQuestion = createNewQuestion(type, survey.elements.length);
    setSurvey(prevSurvey => ({
      ...prevSurvey,
      elements: [...prevSurvey.elements, newQuestion]
    }));

    if (!dragged) {
      setTimeout(() => {
        if (lastQuestionRef.current) {
          lastQuestionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  };

  const updateQuestion = (index, updatedFields) => {
    setSurvey(prevSurvey => ({
      ...prevSurvey,
      elements: prevSurvey.elements.map((q, i) =>
        i === index ? { ...q, ...updatedFields } : q
      )
    }));
  };

  const duplicateQuestion = (index) => {
    const questionToDuplicate = survey.elements[index];
    if (questionToDuplicate) {
      const duplicatedQuestion = {
        ...questionToDuplicate,
        name: `question${survey.elements.length + 1}`
      };
      setSurvey(prevSurvey => ({
        ...prevSurvey,
        elements: [
          ...prevSurvey.elements.slice(0, index + 1),
          duplicatedQuestion,
          ...prevSurvey.elements.slice(index + 1)
        ]
      }));
    }
  };

  const deleteQuestion = (index) => {
    setSurvey(prevSurvey => ({
      ...prevSurvey,
      elements: prevSurvey.elements.filter((_, i) => i !== index)
    }));
  };

  const handleSurveyUpdate = (updates) => {
    setSurvey(prevSurvey => ({ ...prevSurvey, ...updates }));
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    if (source.droppableId === 'sidebar' && destination.droppableId === 'questions') {
      // A new question is being added from the sidebar
      const newQuestionType = draggableId;
      const newQuestion = createNewQuestion(newQuestionType, destination.index);

      setSurvey(prevSurvey => {
        const newElements = Array.from(prevSurvey.elements);
        newElements.splice(destination.index, 0, newQuestion);
        return { ...prevSurvey, elements: newElements };
      });
    } else if (source.droppableId === 'questions' && destination.droppableId === 'questions') {
      // Reordering existing questions
      setSurvey(prevSurvey => {
        const newElements = Array.from(prevSurvey.elements);
        const [reorderedItem] = newElements.splice(source.index, 1);
        newElements.splice(destination.index, 0, reorderedItem);
        return { ...prevSurvey, elements: newElements };
      });
    }
  };

  const createNewQuestion = (type, index) => {
    const newQuestion = {
      type: type,
      name: `question${index + 1}`,
      title: '',
      isRequired: false,
    };

    if (['dropdown', 'tagbox', 'checkbox', 'radiogroup', 'ranking'].includes(type)) {
      newQuestion.choices = [
        { value: 'Item 1', text: 'Option 1' }
      ];
    }

    if (type === 'rating') {
      newQuestion.rateCount = 5;
      newQuestion.rateMax = 5;
    }

    return newQuestion;
  };

  const [showPrivacyPolicyDialog, setShowPrivacyPolicyDialog] = useState(false);
  const [privacyPolicyType, setPrivacyPolicyType] = useState(user?.data.user.privacy_policy_type || 'link');
  const [privacyPolicyContent, setPrivacyPolicyContent] = useState(user?.data.user.privacy_policy || '');
  const [privacyPolicyError, setPrivacyPolicyError] = useState('');
  const [privacyPolicySubmitting, setPrivacyPolicySubmitting] = useState(false);
  const [showAiModal, setShowAiModal] = useState(false);
  const [aiDescription, setAiDescription] = useState('');
  const [aiQuestionCount, setAiQuestionCount] = useState(5);
  const [aiLoading, setAiLoading] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [surveyCredits, setSurveyCredits] = useState(user?.data.user.credits);
  const [max_question, setMaxQuestion] = useState(user?.data.user.max_question || 15);
  const [isSubscribed, setIsSubscribed] = useState(user?.data.user.is_subscribed);

  const [privacyPolicyUpdated, setPrivacyPolicyUpdated] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [isSubmittingSurvey, setIsSubmittingSurvey] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('');
  const { updateUser } = useAuth();
  // questionCount
  const processingMessages = [
    "Crafting the perfect survey...",
    "Polishing questions to perfection...",
    "Sprinkling some survey magic...",
    "Preparing insights for you...",
    "Making your survey irresistible...",
    "Aligning the survey stars...",
    "Giving your survey superpowers...",
    "Brewing a potion of engagement...",
    "Tuning up the response-o-meter...",
    "Teaching your survey to dance..."
  ];

  useEffect(() => {
    let interval;
    if (isProcessing) {
      interval = setInterval(() => {
        setProcessingMessage(processingMessages[Math.floor(Math.random() * processingMessages.length)]);
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isProcessing]);

  const chatId = Math.random().toString(36).substring(2, 15);

  const handlePrivacyPolicy = () => {
    setShowPrivacyPolicyDialog(true);
  };

  const handlePrivacyPolicySubmit = async () => {


    if (privacyPolicyType === 'link' && !isValidUrl(privacyPolicyContent)) {
      setPrivacyPolicyError('Please enter a valid URL');
      return;
    }

    if (privacyPolicyContent.trim() === '') {
      setPrivacyPolicyError('Please enter the privacy policy content');
      return;
    }


    setPrivacyPolicySubmitting(true);
    setPrivacyPolicyError('');
    try {
      const response = await axiosApi.post('/api/savePrivacyPolicy',
        {
          type: privacyPolicyType,
          content: privacyPolicyContent
        }
      );

      if (response.data.error) {
        setPrivacyPolicyError(response.data.message || 'An error occurred while saving the privacy policy');
      } else {
        setPrivacyPolicyUpdated(true);
        setShowPrivacyPolicyDialog(false);
      }
    } catch (error) {
      setPrivacyPolicyError('An error occurred while saving the privacy policy');
      setPrivacyPolicySubmitting(false);

    } finally {
      setPrivacyPolicySubmitting(false);
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleGenerateAI = useCallback(async () => {
    setAiLoading(true);
    try {
      const response = await sendMsgToOpenAI(`
        You are an AI assistant specialized in generating SurveyJS-compatible JSON. Your task is to create a survey according to the user's description and specified number of questions. The survey must include only the following supported question types: short answer (text), long answer (comment), dropdown, multi-select (tagbox), checkbox, boolean, rating, radio group, and ranking. For each survey, ensure the format follows the example provided below, generating questions dynamically as described by the user. Respond only with a valid JSON object representing the survey. Do not include any explanations, text, or markdown formatting.
        
        The only supported question types and format are as follows:
        
        {
          "title": "Comprehensive Survey Example",
          "description": "This survey demonstrates all supported question types",
          "elements": [
            {
              "type": "text",
              "name": "shortAnswer1",
              "title": "Please enter your name:",
              "isRequired": true
            },
            {
              "type": "comment",
              "name": "longAnswer1",
              "title": "Please describe your experience:",
              "isRequired": false
            },
            {
              "type": "dropdown",
              "name": "dropdown1",
              "title": "Select your favorite color:",
              "isRequired": true,
              "choices": [
                { "value": "red", "text": "Red" },
                { "value": "blue", "text": "Blue" },
                { "value": "green", "text": "Green" }
              ]
            },
            {
              "type": "tagbox",
              "name": "multiSelect1",
              "title": "Select all that apply:",
              "isRequired": false,
              "choices": [
                { "value": "option1", "text": "Option 1" },
                { "value": "option2", "text": "Option 2" },
                { "value": "option3", "text": "Option 3" }
              ]
            },
            {
              "type": "checkbox",
              "name": "checkbox1",
              "title": "Which features do you use?",
              "isRequired": true,
              "choices": [
                { "value": "feature1", "text": "Feature 1" },
                { "value": "feature2", "text": "Feature 2" },
                { "value": "feature3", "text": "Feature 3" }
              ]
            },
            {
              "type": "boolean",
              "name": "boolean1",
              "title": "Do you agree with our terms?",
              "isRequired": true
            },
            {
              "type": "rating",
              "name": "rating1",
              "title": "How would you rate our service?",
              "isRequired": true,
              "rateMax": 5
            },
            {
              "type": "radiogroup",
              "name": "radiogroup1",
              "title": "What is your preferred contact method?",
              "isRequired": true,
              "choices": [
                { "value": "email", "text": "Email" },
                { "value": "phone", "text": "Phone" },
                { "value": "mail", "text": "Mail" }
              ]
            },
            {
              "type": "ranking",
              "name": "ranking1",
              "title": "Rank the following in order of importance:",
              "isRequired": true,
              "choices": [
                { "value": "price", "text": "Price" },
                { "value": "quality", "text": "Quality" },
                { "value": "service", "text": "Customer Service" }
              ]
            }
          ]
        }
        `, `Generate a survey JSON with ${aiQuestionCount} questions based on the following description: ${aiDescription}. Please ensure the response is valid JSON only, without any additional explanations.`, chatId);

      try {
        const surveyJson = JSON.parse(response);
        setSurvey(surveyJson);
      } catch (error) {
        console.error("Error parsing AI response directly:", error);

        const jsonMatch = response.match(/```json([\s\S]*?)```/);
        if (jsonMatch && jsonMatch[1]) {
          try {
            const surveyJson = JSON.parse(jsonMatch[1].trim());
            setSurvey(surveyJson);
          } catch (innerError) {
            console.error("Error parsing JSON from code block:", innerError);
          }
        } else {
          console.error("No JSON found in the AI response.");
        }
      }
    } catch (error) {
      console.error("Error communicating with OpenAI:", error);
    } finally {
      setAiLoading(false);
      setShowAiModal(false);
    }
  }, [aiDescription, aiQuestionCount]);

  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  const [logicRules, setLogicRules] = useState([]);

  const handleLogicUpdate = (updatedRules) => {
    setLogicRules(updatedRules);
    const updatedElements = survey.elements.map(element => {
      const rule = updatedRules.find(r => r.targetQuestion.value === element.name);
      if (rule) {
        return {
          ...element,
          visibleIf: `{${rule.triggerQuestion.value}} ${rule.condition.value} '${rule.value}'`
        };
      }
      const { visibleIf, ...elementWithoutVisibleIf } = element;
      return elementWithoutVisibleIf;
    });

    setSurvey(prevSurvey => ({
      ...prevSurvey,
      elements: updatedElements
    }));
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };


  useEffect(() => {
    if (isEditMode) {
      const fetchSurvey = async () => {
        setLoading(true);
        try {
          const response = await axiosApi.get(`/api/survey/fetchSurvey?survey_id=${id}`);

          if (!response.data.error) {
            setSurvey(response.data.data);
          } else {
            console.error('Error fetching survey data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching survey data:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchSurvey();
    }
  }, [id, isEditMode]);

  // Add this new state variable near the top of your component
  const [showCreditUpgradeDialog, setShowCreditUpgradeDialog] = useState(false);

  // Update the handleSubmitSurvey function
  const handleSubmitSurvey = useCallback(() => {
    if (finalQuestionCount === 0) {
      setDialogMessage('Please add at least one question to your survey.');
      setDialogSuccess(false);
      setShowDialog(true);
      return;
    }

    if (!isEditMode) {
      if (!(isSubscribed == 1)) {
        setDialogMessage('You must have an active subscription to create a new survey.');
        setDialogSuccess(false);
        setShowDialog(true);
        return;
      }

      if (surveyCredits <= 0) {
        setShowCreditUpgradeDialog(true);
        return;
      }

      if (finalQuestionCount > max_question) {
        setShowUpgradeDialog(true);
        return;
      }
    }


    if (!privacyPolicyUpdated) {
      if (!user.data.user.privacy_policy && !user.data.user.privacy_policy_type) {
        setIsSubmittingSurvey(true);
        setShowPrivacyPolicyDialog(true);
        return;
      }
    }
    submitSurvey(survey);



  }, [
    isEditMode,
    surveyCredits,
    max_question,
    user.data.user.privacy_policy,
    finalQuestionCount,
    survey,
    isSubscribed
  ]);

  const normalizeSurveyJson = (surveyJson) => {
    if (survey.elements && Array.isArray(survey.elements)) {
      survey.elements.forEach((element) => {
        if (element.choices && Array.isArray(element.choices)) {
          element.choices = element.choices.map((choice) => {
            if (typeof choice === 'string') {
              return { value: choice, text: choice };
            } else if (typeof choice === 'object' && choice !== null && 'value' in choice && 'text' in choice) {
              return choice;
            } else {
              return { value: choice, text: choice };
            }
          });
        }
      });
    }
    return surveyJson;
  };

  const submitSurvey = (surveyJson) => {
    setIsProcessing(true);
    setProcessingMessage(processingMessages[Math.floor(Math.random() * processingMessages.length)]);

    surveyJson.user_id = user.data.user.id;
    if (isEditMode) {
      surveyJson.id = id;
    }
    const normalizedSurveyJson = normalizeSurveyJson(surveyJson);
    const apiUrl = isEditMode
      ? '/api/survey/update'
      : '/api/survey/create';

    if (!normalizedSurveyJson.logoWidth) {
      normalizedSurveyJson.logoWidth = "100px";
    }
    if (!normalizedSurveyJson.logoFit) {
      normalizedSurveyJson.logoFit = "fill";
    }
    if (!normalizedSurveyJson.logoPosition) {
      normalizedSurveyJson.logoPosition = "right";
    }

    saveSurveyJson(apiUrl, normalizedSurveyJson, (success) => {
      setIsProcessing(false);
      setLoading(false);
      if (success) {
        if (!isEditMode) {
          localStorage.removeItem('currentSurvey');
        }
        setDialogMessage('Your survey was submitted successfully!');
        setDialogSuccess(true);

      } else {
        setDialogMessage('There was an error submitting your survey.');
        setDialogSuccess(false);
      }
      setShowDialog(true);
    });
  };

  async function saveSurveyJson(url, json, callback) {

    await axiosApi.post(url, json
    )
      .then(response => response.data)
      .then(data => {
        if (data.error) {
          callback(false);
        } else {
          callback(true);
        }
      })
      .catch(() => {
        callback(false);
      });
  }

  const handleBack = () => {
    navigate(-1);
  }

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-white">
        <div className="text-center">
          <FiLoader className="animate-spin text-purple-600 text-6xl mx-auto mb-4" />
          <h2 className="text-2xl font-bold mb-4 text-purple-700">Loading Survey</h2>
          <p className="text-gray-600">Please wait while we fetch your survey data...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="fixed top-0 left-0 right-0 z-50 bg-purple-50 shadow-md">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <div className="flex items-center">
                <FiArrowLeft onClick={handleBack} className="h-6 w-6 text-purple-600 mr-2" />
                <img
                  src="/mainlogo.png"
                  alt="Logo"
                  className="h-8 w-auto mr-4"
                />
              </div>
            </div>
            <div className="flex items-center space-x-2 sm:space-x-4">
              <div className="hidden md:flex items-center space-x-2 sm:space-x-4">
                <button
                  onClick={() => setShowAiModal(true)}
                  className="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-purple-600 to-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-md hover:shadow-lg hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5 group"
                >
                  <Sparkle className="mr-2 h-5 w-5 transition-transform duration-300 ease-in-out group-hover:rotate-90" />
                  <span>Generate Via AI</span>
                </button>
                <button
                  onClick={handlePrivacyPolicy}
                  className="inline-flex items-center justify-center rounded-md bg-purple-100 px-4 py-2 text-sm font-medium text-purple-700 shadow-md hover:shadow-lg hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-purple-300 focus:ring-offset-2 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5"
                >
                  Privacy Policy
                </button>
              </div>

              <div className="md:hidden">
                <button
                  onClick={toggleMobileMenu}
                  className="inline-flex items-center justify-center p-2 rounded-md text-purple-600 hover:text-purple-700 hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 transition-colors duration-200"
                  aria-expanded={mobileMenuOpen}
                >
                  <span className="sr-only">
                    {mobileMenuOpen ? 'Close main menu' : 'Open main menu'}
                  </span>
                  {mobileMenuOpen ? (
                    <FiX className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <FiMenu className="h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>
              <button
                onClick={handleSubmitSurvey}
                className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-md hover:shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5 group"
              >
                <span className="mr-2">{isEditMode ? "Update Survey" : "Create Survey"}</span>
                <MdGeneratingTokens className="h-4 w-4 transition-transform duration-300 ease-in-out group-hover:translate-x-1" />
              </button>
            </div>
          </div>
        </div>

        <div
          className={`md:hidden transition-all duration-300 ease-in-out ${mobileMenuOpen ? 'max-h-48 opacity-100' : 'max-h-0 opacity-0'
            } overflow-hidden`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button
              disabled={isSubmittingSurvey}
              onClick={() => {
                setShowAiModal(true);
                setMobileMenuOpen(false);
              }}
              className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 transition-colors duration-200"
            >
              <div className="flex items-center">
                <Sparkle className="mr-2 h-5 w-5" />
                <span>Generate Via AI</span>
              </div>
            </button>
            <button
              onClick={() => {
                setShowPrivacyPolicyDialog(true);
                setMobileMenuOpen(false);
              }}
              className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-purple-700 bg-purple-100 hover:bg-purple-200 transition-colors duration-200"
            >
              Privacy Policy
            </button>
          </div>
        </div>
      </header>

      <nav className="fixed top-16 left-0 right-0 z-40 bg-white shadow-sm">
        <div className="mx-auto px-2 sm:px-4 lg:px-6">
          <div className="flex h-12 items-center overflow-x-auto scrollbar-hide">
            {[
              { id: 'designer', icon: FiGrid, label: 'Designer' },
              { id: 'preview', icon: FiEye, label: 'Preview' },
              { id: 'conditional question', icon: FiSettings, label: 'Conditional Question Formatting' },
              { id: 'json', icon: FiCode, label: 'JSON', hidden: !jsonEditorEnabled },
            ].map((tab) =>
              !tab.hidden && (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex items-center rounded-md px-2 sm:px-3 py-1 sm:py-2 text-xs sm:text-sm font-medium whitespace-nowrap transition-all duration-200 mr-2 sm:mr-4 ${activeTab === tab.id
                    ? 'bg-purple-100 text-purple-700'
                    : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                    }`}
                >
                  <tab.icon className="mr-1 sm:mr-2" />
                  <span className="hidden sm:inline">{tab.label}</span>
                  <span className="sm:hidden">{tab.label.slice(0, 9)}</span>
                </button>
              )
            )}
          </div>
        </div>
      </nav>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex min-h-screen pt-28">
          {activeTab === 'designer' && (
            <aside className={`fixed top-28 left-0 z-30 h-[calc(100vh-7rem)] w-64 bg-white shadow-lg transition-transform duration-300 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'
              } md:translate-x-0`}>
              <div className="h-full overflow-y-auto p-4">
                <Droppable droppableId="sidebar" isDropDisabled={false}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="space-y-4"
                    >
                      {questionTypes.map((type, index) => (
                        <Draggable key={type.id} draggableId={type.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="flex cursor-pointer items-center rounded-md border border-gray-200 bg-white p-3 shadow-sm hover:bg-gray-50 transition-all duration-200"
                              onClick={() => addQuestion(type.id, false)}
                            >
                              <div
                                {...provided.dragHandleProps}
                                className="flex h-10 w-10 items-center justify-center rounded-md bg-purple-100 text-purple-600"
                              >
                                {React.cloneElement(type.icon, { size: 24 })}
                              </div>
                              <span className="ml-3 text-sm font-medium text-gray-700">
                                {type.name}
                              </span>
                              <MdDragIndicator className="ml-auto text-gray-400" />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </aside>
          )}

          <main
            className={`flex-grow p-4 sm:p-8 transition-all duration-300 ${activeTab === 'designer' ? 'md:ml-64' : ''
              }`}
          >
            <div className="mx-auto max-w-5xl">
              {(activeTab === 'designer' || activeTab === 'preview') && (
                <SurveyHeader
                  surveyTitle={survey.title}
                  surveyDescription={survey.description}
                  surveyLogo={survey.logo}
                  onSurveyUpdate={handleSurveyUpdate}
                />
              )}

              {activeTab === 'designer' && (
                <Droppable droppableId="questions">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="mt-8 space-y-6"
                    >
                      {survey.elements.length === 0 ? (
                        <div className="flex flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-white p-12 text-center">
                          <img
                            src="/survey.jpg"
                            alt="Empty form illustration"
                            className="mb-6 h-48 w-48"
                          />
                          <h2 className="mb-2 text-2xl font-bold text-gray-800">
                            Your form is empty
                          </h2>
                          <p className="mb-6 text-gray-500">
                            Drag an element from the toolbox or click the button below.
                          </p>
                          <button
                            onClick={() => addQuestion('text')}
                            className="inline-flex items-center rounded-lg bg-purple-600 px-6 py-3 text-lg font-semibold text-white shadow hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition-all duration-200"
                          >
                            <FiPlusCircle className="mr-2" size={20} />
                            Add Question
                          </button>
                        </div>
                      ) : (
                        survey.elements.map((question, index) => (
                          <Draggable key={question.name} draggableId={question.name} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={(el) => {
                                  provided.innerRef(el);
                                  if (index === survey.elements.length - 1) {
                                    lastQuestionRef.current = el;
                                  }
                                }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`rounded-lg bg-white p-6 shadow-md ${snapshot.isDragging ? 'border-2 border-purple-500' : ''
                                  } transition-all duration-200`}
                              >
                                <QuestionItem
                                  question={question}
                                  questionNumber={index + 1}
                                  questionTypes={questionTypes}
                                  onUpdate={(updatedFields) => updateQuestion(index, updatedFields)}
                                  onDuplicate={() => duplicateQuestion(index)}
                                  onDelete={() => deleteQuestion(index)}
                                  isSelected={selectedQuestionId === question.name}
                                  onSelect={(id) => setSelectedQuestionId(id)}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}

              {activeTab === 'preview' && (
                <PreviewPage survey={survey} />
              )}
              {activeTab === 'conditional question' && (
                <LogicBuilderPage
                  questions={survey.elements}
                  onLogicUpdate={handleLogicUpdate}
                  existingRules={logicRules}
                />
              )}
              {activeTab === 'json' && (
                <JSONEditor
                  survey={survey}
                  onSurveyUpdate={(updatedSurvey) => setSurvey(updatedSurvey)}
                />
              )}

              {activeTab === 'designer' && survey.elements.length > 0 && (
                <div className="mt-8 flex justify-center">
                  <button
                    onClick={() => addQuestion('text')}
                    className="inline-flex items-center rounded-md bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition-all duration-200"
                  >
                    <FiPlusCircle className="mr-2" />
                    Add Question
                  </button>
                </div>
              )}
            </div>
          </main>
        </div>
      </DragDropContext>

      {activeTab === 'designer' && (
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="fixed bottom-4 left-4 z-50 rounded-full bg-purple-600 p-3 text-white shadow-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition-all duration-200 md:hidden"
          aria-label="Toggle Sidebar"
        >
          {sidebarOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      )}
      <Suspense fallback={<div className="w-32 h-8 bg-gray-200 animate-pulse rounded mx-auto mt-2"></div>}>
        <AIbot openAfterFive={true} />
      </Suspense>

      {showPrivacyPolicyDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-2xl shadow-2xl max-w-2xl w-full">
            <h2 className="text-2xl font-bold mb-6 text-purple-700">
              {user.data.user.privacy_policy ? "Update Privacy Policy" : "Add Privacy Policy"}
            </h2>
            <p className="mb-6 text-gray-600">
              To ensure compliance with data protection regulations, please provide your privacy policy before submitting the survey. This helps protect both you and your survey participants. You can use our privacy policy as long as you comply with your own privacy policy. We are not responsible for state privacy laws.
            </p>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">Policy Type:</label>
              <div className="flex space-x-4">
                <button
                  className={`px-4 py-2 rounded-full ${privacyPolicyType === 'link' ? 'bg-purple-600 text-white' : 'bg-gray-200 text-gray-700'} focus:outline-none transition duration-300`}
                  onClick={() => setPrivacyPolicyType('link')}
                >
                  Link
                </button>
                <button
                  className={`px-4 py-2 rounded-full ${privacyPolicyType === 'text' ? 'bg-purple-600 text-white' : 'bg-gray-200 text-gray-700'} focus:outline-none transition duration-300`}
                  onClick={() => setPrivacyPolicyType('text')}
                >
                  Text
                </button>
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {privacyPolicyType === 'link' ? 'Privacy Policy URL:' : 'Privacy Policy Text:'}
              </label>
              {privacyPolicyType === 'link' ? (
                <input
                  type="url"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  value={privacyPolicyContent}
                  onChange={(e) => setPrivacyPolicyContent(e.target.value)}
                  placeholder="https://example.com/privacy-policy"
                />
              ) : (
                <textarea
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  value={privacyPolicyContent}
                  onChange={(e) => setPrivacyPolicyContent(e.target.value)}
                  placeholder="Enter your privacy policy text here..."
                  rows={8}
                />
              )}
            </div>
            {privacyPolicyError && (
              <p className="text-red-500 mb-4">{privacyPolicyError}</p>
            )}
            <div className="flex justify-end space-x-4">
              <button
                className="px-6 py-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 focus:outline-none transition duration-300"
                onClick={() => setShowPrivacyPolicyDialog(false)}
              >
                Cancel
              </button>
              <button
                className={`px-6 py-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 focus:outline-none transition duration-300 ${privacyPolicySubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handlePrivacyPolicySubmit}
                disabled={privacyPolicySubmitting}
              >
                {privacyPolicySubmitting ? 'Submitting...' : user?.data.user.privacy_policy ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </div>
      )}

      {showDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-2xl shadow-2xl max-w-md w-full text-center">
            {dialogSuccess ? (
              <>
                <div className="mb-6">
                  <svg className="w-16 h-16 text-green-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </div>
                <h2 className="text-3xl font-bold mb-4 text-green-600">Success!</h2>
                <p className="text-xl text-gray-700 mb-6">Your survey was submitted successfully!</p>
                <p className="text-gray-600 mb-8">You can now view and manage your survey from your dashboard.</p>
                <div className="flex justify-center space-x-4">
                  <button
                    className="px-6 py-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 focus:outline-none transition duration-300"
                    onClick={() => setShowDialog(false)}
                  >
                    Close
                  </button>
                  <button
                    className="px-6 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 focus:outline-none transition duration-300"
                    onClick={() => navigate('/dashboard')}
                  >
                    Go to Dashboard
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="mb-6">
                  <svg className="w-16 h-16 text-red-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </div>
                <h2 className="text-3xl font-bold mb-4 text-red-600">Warning!</h2>
                <p className="text-xl text-gray-700 mb-6">{dialogMessage}</p>
                <button
                  className="px-6 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none transition duration-300"
                  onClick={() => {
                    setShowDialog(false);
                    if (dialogMessage === 'You must have an active subscription to create a new survey.') {
                      setShowSubscribeModal(true);
                    }
                  }}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {showAiModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-2xl shadow-2xl max-w-2xl w-full">
            {aiLoading ? (
              <div className="text-center">
                <div className="mb-6">
                  <Sparkle className="animate-pulse text-purple-600 text-6xl mx-auto" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-purple-700">AI Magic in Progress</h2>
                <p className="text-gray-600 mb-6">Our AI is crafting your perfect survey. This might take a moment...</p>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-4">
                  <div className="bg-purple-600 h-2.5 rounded-full animate-pulse" style={{ width: '100%' }}></div>
                </div>
                <div className="space-y-2">
                  <p className="text-sm text-gray-500">🧠 Analyzing your description</p>
                  <p className="text-sm text-gray-500">📊 Structuring questions</p>
                  <p className="text-sm text-gray-500">✨ Adding AI-powered enhancements</p>
                </div>
              </div>
            ) : (
              <>
                <h2 className="text-2xl font-bold mb-6 text-purple-700">Generate Survey via AI</h2>
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Survey Description:</label>
                  <textarea
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    value={aiDescription}
                    onChange={(e) => setAiDescription(e.target.value)}
                    placeholder="Describe your survey..."
                    rows={4}
                  />
                </div>
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Number of Questions:</label>
                  <input
                    type="number"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    value={aiQuestionCount}
                    onChange={(e) => setAiQuestionCount(parseInt(e.target.value))}
                    min={1}
                    max={20}
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    className="px-6 py-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 focus:outline-none transition duration-300"
                    onClick={() => setShowAiModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-6 py-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 focus:outline-none transition duration-300 flex items-center"
                    onClick={handleGenerateAI}
                    disabled={aiLoading}
                  >
                    <Sparkle className="mr-2" />
                    Generate
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {isProcessing && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-2xl shadow-2xl max-w-md w-full text-center">
            <FiLoader className="animate-spin text-purple-600 text-4xl mx-auto mb-4" />
            <h2 className="text-2xl font-bold mb-4 text-purple-700">Processing Your Survey</h2>
            <p className="text-gray-600 mb-4">{processingMessage}</p>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-4">
              <div className="bg-purple-600 h-2.5 rounded-full w-full animate-pulse"></div>
            </div>
            <p className="text-sm text-gray-500">This may take a moment. Please don't close the browser.</p>
          </div>
        </div>
      )}

      {showUpgradeDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-2xl shadow-2xl max-w-md w-full text-center">
            <h2 className="text-2xl font-bold mb-4 text-purple-700">Upgrade Your Plan</h2>
            <p className="text-gray-600 mb-6">
              You've reached the maximum number of questions ({max_question}) for your current plan.
              Upgrade now to add more questions and unlock additional features!
            </p>
            <div className="flex justify-center space-x-4">
              <button
                className="px-6 py-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 focus:outline-none transition duration-300"
                onClick={() => setShowUpgradeDialog(false)}
              >
                Cancel
              </button>
              <button
                className="px-6 py-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 focus:outline-none transition duration-300"
                onClick={() => {
                  setShowSubscribeModal(true);
                  setShowUpgradeDialog(false);
                }}
              >
                Upgrade Now
              </button>
            </div>
          </div>
        </div>
      )}



      {showSubscribeModal && (
        <SubscribeModal onClose={(value) => setShowSubscribeModal(value)} />

      )}
      {showCreditUpgradeDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-2xl shadow-2xl max-w-md w-full text-center">
            <h2 className="text-2xl font-bold mb-4 text-purple-700">Insufficient Credits</h2>
            <p className="text-gray-600 mb-6">
              You don't have enough credits to create a new survey.
              Upgrade your plan or purchase more credits to continue creating amazing surveys!
            </p>
            <div className="flex justify-center space-x-4">
              <button
                className="px-6 py-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 focus:outline-none transition duration-300"
                onClick={() => setShowCreditUpgradeDialog(false)}
              >
                Cancel
              </button>
              <button
                className="px-6 py-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 focus:outline-none transition duration-300"
                onClick={() => {

                  setShowSubscribeModal(true);
                  setShowCreditUpgradeDialog(false);
                }}
              >
                Upgrade Now
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}