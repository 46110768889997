import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FiPlusCircle, FiTrash2, FiInfo, FiChevronRight } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

const LogicBuilderPage = ({ questions, onLogicUpdate, existingRules }) => {
  const [rules, setRules] = useState(existingRules);
  const [currentRule, setCurrentRule] = useState({
    id: uuidv4(),
    triggerQuestion: null,
    condition: null,
    value: '',
    action: null,
    targetQuestion: null,
  });

  useEffect(() => {
    onLogicUpdate(rules);
  }, [rules, onLogicUpdate]);

  const questionOptions = questions.map(q => ({ value: q.name, label: q.title }));

  const conditionOptions = [
    { value: '=', label: 'Equals' },
    { value: '!=', label: 'Does not equal' },
    { value: 'contains', label: 'Contains' },
    { value: 'not contains', label: 'Does not contain' },
    { value: '>', label: 'Greater than' },
    { value: '<', label: 'Less than' },
    { value: 'empty', label: 'Is empty' },
    { value: 'notempty', label: 'Is not empty' },
  ];

  const actionOptions = [
    { value: 'show', label: 'Show' },
    { value: 'hide', label: 'Hide' },
  ];

  const handleInputChange = (field, value) => {
    setCurrentRule(prev => ({ ...prev, [field]: value }));
  };

  const handleAddRule = () => {
    if (currentRule.triggerQuestion && currentRule.condition && currentRule.action && currentRule.targetQuestion) {
      setRules(prev => [...prev, currentRule]);
      setCurrentRule({
        id: uuidv4(),
        triggerQuestion: null,
        condition: null,
        value: '',
        action: null,
        targetQuestion: null,
      });
    } else {
      alert('Please fill in all required fields.');
    }
  };

  const handleDeleteRule = (id) => {
    setRules(prev => prev.filter(rule => rule.id !== id));
  };

  const generateLogicString = (rule) => {
    const triggerQuestion = rule.triggerQuestion.label;
    const condition = rule.condition.label.toLowerCase();
    const value = rule.value || '(any value)';
    const action = rule.action.label.toLowerCase();
    const targetQuestion = rule.targetQuestion.label;

    return `If "${triggerQuestion}" ${condition} "${value}", then ${action} "${targetQuestion}".`;
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 sm:p-6 md:p-8">
      <div className="max-w-7xl mx-auto bg-white rounded-xl shadow-2xl overflow-hidden">
        <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white p-6 sm:p-8">
          <h1 className="text-2xl sm:text-3xl font-bold">Logic Builder</h1>
          <p className="mt-2 text-purple-100 text-sm sm:text-base">Create conditional logic for your survey questions</p>
        </div>

        <div className="p-4 sm:p-6 md:p-8 space-y-6 sm:space-y-8">
          <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-md shadow-sm">
            <div className="flex items-start sm:items-center">
              <div className="flex-shrink-0">
                <FiInfo className="h-5 w-5 sm:h-6 sm:w-6 text-blue-400" />
              </div>
              <div className="ml-3">
                <p className="text-xs sm:text-sm text-blue-700">
                  Use this builder to create logic rules for your survey. Each rule consists of a trigger question, a condition, and an action to be applied to a target question.
                </p>
              </div>
            </div>
          </div>

          <div className="space-y-4 sm:space-y-6">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">Create a New Rule</h2>
            <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm space-y-4">
              <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
                <span className="text-gray-700 font-medium">If</span>
                <Select
                  options={questionOptions}
                  value={currentRule.triggerQuestion}
                  onChange={(selected) => handleInputChange('triggerQuestion', selected)}
                  placeholder="Select question..."
                  className="w-full sm:w-64 shadow-sm"
                />
                <Select
                  options={conditionOptions}
                  value={currentRule.condition}
                  onChange={(selected) => handleInputChange('condition', selected)}
                  placeholder="Condition..."
                  className="w-full sm:w-48 shadow-sm"
                />
                <input
                  type="text"
                  value={currentRule.value}
                  onChange={(e) => handleInputChange('value', e.target.value)}
                  placeholder="Enter value..."
                  className="w-full sm:w-48 border rounded-md p-2 shadow-sm focus:ring-2 focus:ring-purple-300 focus:border-purple-300"
                />
              </div>
              <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
                <span className="text-gray-700 font-medium">Then</span>
                <Select
                  options={actionOptions}
                  value={currentRule.action}
                  onChange={(selected) => handleInputChange('action', selected)}
                  placeholder="Select action..."
                  className="w-full sm:w-48 shadow-sm"
                />
                <Select
                  options={questionOptions}
                  value={currentRule.targetQuestion}
                  onChange={(selected) => handleInputChange('targetQuestion', selected)}
                  placeholder="Select target question..."
                  className="w-full sm:w-64 shadow-sm"
                />
              </div>
              <button
                onClick={handleAddRule}
                className="w-full sm:w-auto mt-4 bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-6 py-2 rounded-md hover:from-purple-700 hover:to-indigo-700 transition duration-300 ease-in-out flex items-center justify-center space-x-2 shadow-md"
              >
                <FiPlusCircle />
                <span>Add Rule</span>
              </button>
            </div>
          </div>

          <div className="space-y-4 sm:space-y-6">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">Existing Rules</h2>
            {rules.length === 0 ? (
              <p className="text-gray-500 italic">No rules defined yet.</p>
            ) : (
              <ul className="space-y-4">
                {rules.map((rule) => (
                  <li key={rule.id} className="bg-white p-4 rounded-lg shadow-md flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0 hover:shadow-lg transition duration-300">
                    <div className="flex items-center space-x-2">
                      <FiChevronRight className="text-purple-500 flex-shrink-0" />
                      <span className="text-gray-800 text-sm sm:text-base">{generateLogicString(rule)}</span>
                    </div>
                    <button
                      onClick={() => handleDeleteRule(rule.id)}
                      className="text-red-500 hover:text-red-700 transition duration-300"
                    >
                      <FiTrash2 size={20} />
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogicBuilderPage;