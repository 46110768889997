import axiosApi from "./axiosApi";


export const login = async (email, password) => {
    try {
        const response = await axiosApi.post('/api/login', { email, password });
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data; // This should include the error message
        }
        throw error; // Re-throw unexpected errors
    }
};

export async function logout() {
    const req = await axiosApi.post('/api/logout');
    localStorage.removeItem("tk");
    return req.data;
}



export default function register(user) {
    return axiosApi.post("/api/register", user)
}

export async function currentUser() {
    const req = await axiosApi.get("/api/me")

    return req.data
}




export async function getSubscriptionStatus() {
    const req = await axiosApi.get("/api/user/latest-subscription")
    console.log(req.data);
    return req.data
}




export async function sendPasswordResetEmail(email) {
    const req = await axiosApi.post("/auth/forget-password", { email })
    return req.data
}

export async function sendResetPassword(payload) {
    const req = await axiosApi.post("/auth/reset-password", payload)
    return req.data
}

export const verifyOTPApi = async (email, password, otp) => {

    const response = await axiosApi.post('/api/verifyOtp', {
        email, password, otp
    });

    return response.data;
};
