import React from 'react';

export function NotAllowed() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Access Denied</h1>
        <p className="text-gray-700 mb-4">
          You do not have permission to view this page. This area is restricted to administrators only.
        </p>
        <p className="text-gray-600">
          If you believe this is an error, please contact the system administrator for assistance.
        </p>
      </div>
    </div>
  );
}
