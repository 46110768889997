import React, { useState, useEffect } from 'react';
import {
  FiCopy,
  FiTrash2,
  FiToggleLeft,
  FiToggleRight,
  FiPlusCircle,
  FiMove,
  FiChevronDown,
} from 'react-icons/fi';
import { Switch } from '@headlessui/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const QuestionItem = ({
  question,
  questionNumber,
  onUpdate,
  onDuplicate,
  onDelete,
  questionTypes,
  isSelected,
  onSelect,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    const updatedQuestion = { ...question, type: newType };

    if (['dropdown', 'tagbox', 'checkbox', 'radiogroup', 'ranking'].includes(newType)) {
      updatedQuestion.choices = [{ value: 'Item 1', text: 'Option 1' }];
    } else {
      delete updatedQuestion.choices;
    }

    if (newType === 'rating') {
      updatedQuestion.rateCount = 5;
      updatedQuestion.rateMax = 5;
    } else {
      delete updatedQuestion.rateCount;
      delete updatedQuestion.rateMax;
    }

    onUpdate(updatedQuestion);
  };

  const handleLabelChange = (e) => {
    onUpdate({ ...question, title: e.target.value });
  };

  const toggleRequired = () => {
    onUpdate({ ...question, isRequired: !question.isRequired });
  };

  const addOption = () => {
    const newChoices = [
      ...question.choices,
      { value: `Item ${question.choices.length + 1}`, text: `Option ${question.choices.length + 1}` }
    ];
    onUpdate({ ...question, choices: newChoices });
  };

  const updateOption = (index, field, value) => {
    const newChoices = question.choices.map((choice, i) =>
      i === index ? { ...choice, [field]: value } : choice
    );
    onUpdate({ ...question, choices: newChoices });
  };

  const deleteOption = (index) => {
    const newChoices = question.choices.filter((_, i) => i !== index);
    onUpdate({ ...question, choices: newChoices });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    onUpdate({ ...question, images: files });
  };

  useEffect(() => {
    return () => {
      if (question.images) {
        question.images.forEach((image) => {
          URL.revokeObjectURL(image);
        });
      }
    };
  }, [question.images]);

  const handleRankingOptionChange = (index, value) => {
    const newChoices = [...question.choices];
    newChoices[index] = { ...newChoices[index], text: value, value: value };
    onUpdate({ ...question, choices: newChoices });
  };

  const handleAddRankingOption = () => {
    const newChoice = { text: `Option ${question.choices.length + 1}`, value: `Option ${question.choices.length + 1}` };
    onUpdate({ ...question, choices: [...question.choices, newChoice] });
  };

  const handleDeleteRankingOption = (index) => {
    const newChoices = question.choices.filter((_, i) => i !== index);
    onUpdate({ ...question, choices: newChoices });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(question.choices);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    onUpdate({ ...question, choices: items });
  };

  const handleRatingScaleChange = (increment) => {
    const newRateMax = Math.max(1, Math.min(10, (question.rateMax || 5) + increment));
    onUpdate({ ...question, rateMax: newRateMax });
  };

  const renderQuestionInput = () => {
    switch (question.type) {
      case 'text':
      case 'comment':
        return (
          <div className="mb-6">
            <input
              type={question.type === 'text' ? 'text' : 'textarea'}
              className="w-full bg-white border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 transition-shadow duration-200"
              placeholder={question.type === 'text' ? "Short answer text" : "Long answer text"}
              readOnly
            />
          </div>
        );
      case 'rating':
        return (
          <div className="mb-6">
            <div className="flex items-center space-x-4 mb-4">
              <label className="text-gray-700 font-medium">Rating Scale:</label>
              <button
                onClick={() => handleRatingScaleChange(-1)}
                className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                -
              </button>
              <span className="text-lg font-semibold">{question.rateMax || 5}</span>
              <button
                onClick={() => handleRatingScaleChange(1)}
                className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                +
              </button>
            </div>
            <div className="flex items-center space-x-2 mb-2 overflow-x-auto pb-2">

              <span className="text-gray-500 text-sm flex-shrink-0">(Most unlikely)</span>
              {[...Array(question.rateMax || 5)].map((_, i) => (
                <button
                  key={i}
                  className="w-8 h-8 rounded-full bg-white border border-gray-300 text-gray-700 font-medium hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-500 transition-colors duration-200 flex-shrink-0"
                >
                  {i + 1}
                </button>
              ))}
              <span className="text-gray-500 text-sm flex-shrink-0">(Most likely)</span>

            </div>
          </div>
        );

      case 'dropdown':
      case 'checkbox':
      case 'tagbox':
      case 'radiogroup':
        return (
          <div className="mb-6">
            <label className="block font-semibold mb-2 text-gray-700">Options:</label>
            <div className="space-y-2">
              {question.choices.map((choice, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    className="w-full border border-gray-300 p-2 rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-purple-500 transition-shadow duration-200"
                    value={choice.text}
                    onChange={(e) => updateOption(index, 'text', e.target.value)}
                    placeholder={`Option ${index + 1}`}
                    aria-label={`Question ${questionNumber} Option ${index + 1}`}
                  />
                  <button
                    onClick={() => deleteOption(index)}
                    className="text-red-500 hover:text-red-700 focus:outline-none transition-colors duration-200"
                    title="Delete Option"
                    aria-label={`Delete Option ${index + 1}`}
                  >
                    <FiTrash2 />
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={addOption}
              className="mt-3 flex items-center text-purple-600 hover:text-purple-800 focus:outline-none transition-colors duration-200"
              aria-label="Add Option"
            >
              <FiPlusCircle className="mr-1" /> Add Option
            </button>
          </div>
        );

      case 'image':
        return (
          <div className="mb-6">
            <label className="block font-semibold mb-2 text-gray-700">Select Images:</label>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageUpload}
              className="w-full text-gray-700 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-purple-50 file:text-purple-700 hover:file:bg-purple-100 transition-colors duration-200"
              aria-label={`Question ${questionNumber} Image Picker`}
            />
            {question.images && question.images.length > 0 && (
              <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4">
                {question.images.map((image, index) => (
                  <div key={index} className="border border-gray-200 rounded-md overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-200">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Uploaded ${index + 1}`}
                      className="object-cover h-32 w-full"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        );

      case 'boolean':
        return (
          <div className="mt-2">
            <Switch.Group>
              <div className="flex items-center">
                <Switch.Label className="mr-4 text-gray-700">No</Switch.Label>
                <Switch
                  checked={question.defaultValue === 'yes'}
                  onChange={(checked) => onUpdate({ ...question, defaultValue: checked ? 'yes' : 'no' })}
                  className={`${question.defaultValue === 'yes' ? 'bg-purple-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2`}
                >
                  <span
                    className={`${question.defaultValue === 'yes' ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
                <Switch.Label className="ml-4 text-gray-700">Yes</Switch.Label>
              </div>
            </Switch.Group>
          </div>
        );

      case 'ranking':
        return (
          <div className="mb-6">
            <label className="block font-semibold mb-2 text-gray-700">Ranking Options:</label>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="ranking-options">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
                    {question.choices.map((choice, index) => (
                      <Draggable key={choice.value} draggableId={choice.value} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="flex items-center space-x-2"
                          >
                            <span {...provided.dragHandleProps} className="cursor-move">
                              <FiMove className="text-gray-400" />
                            </span>
                            <input
                              type="text"
                              className="flex-grow border border-gray-300 p-2 rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-purple-500 transition-shadow duration-200"
                              value={choice.text}
                              onChange={(e) => handleRankingOptionChange(index, e.target.value)}
                              placeholder={`Option ${index + 1}`}
                            />
                            <button
                              onClick={() => handleDeleteRankingOption(index)}
                              className="text-red-500 hover:text-red-700 focus:outline-none transition-colors duration-200"
                              title="Delete Option"
                            >
                              <FiTrash2 />
                            </button>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <button
              onClick={handleAddRankingOption}
              className="mt-3 flex items-center text-purple-600 hover:text-purple-800 focus:outline-none transition-colors duration-200"
            >
              <FiPlusCircle className="mr-1" /> Add Option
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div
      className={`relative p-6 mb-4 transition-all duration-300 ease-in-out ${isSelected || isHovered ? 'bg-purple-50 shadow-lg' : 'bg-white shadow-sm'
        } rounded-lg overflow-hidden`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onSelect(question.name)}
    >
      <div
        className={`absolute inset-0 border-4 border-purple-400 rounded-lg pointer-events-none transition-opacity duration-300 ease-in-out ${isSelected || isHovered ? 'opacity-100' : 'opacity-0'
          }`}
      />
      <div className="relative z-10">
        <div className="flex items-center mb-6">
          {isHovered && (
            <span
              className="cursor-grab mr-3 text-gray-400 hover:text-gray-600 transition-opacity duration-300 ease-in-out"
              title="Drag to reorder"
            >
              <FiMove size={20} />
            </span>
          )}
          <div className="flex-grow flex items-center">
            <span className="text-lg font-semibold text-gray-800 mr-2">{questionNumber}.</span>
            <div className="flex flex-grow items-center">
              <input
                type="text"
                className="w-full bg-transparent text-lg font-semibold text-gray-800 focus:outline-none focus:ring-2 focus:ring-purple-500 rounded-md transition-shadow duration-200 mr-2"
                placeholder={question.name}
                value={question.title}
                onChange={handleLabelChange}
                aria-label={`Question ${questionNumber} Title`}
              />
              {(question.isRequired == 1) && <span className="text-red-500 text-xl">*</span>}
            </div>
          </div>
        </div>

        {renderQuestionInput()}

        {(isHovered || isSelected) && (
          <div
            className={`flex flex-wrap justify-between items-center mt-4 pt-4 border-t border-gray-200 transition-opacity duration-300 ease-in-out ${isHovered || isSelected ? 'opacity-100' : 'opacity-0'
              }`}
          >
            <div className="flex items-center space-x-2 mb-2 sm:mb-0">
              <div className="relative w-40">
                <select
                  value={question.type}
                  onChange={handleTypeChange}
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 pr-8 rounded-md text-sm leading-tight focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-shadow duration-200"
                >
                  <option value="rating">Rating Scale</option>
                  {questionTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <FiChevronDown size={16} />
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={(e) => { e.stopPropagation(); onDuplicate(); }}
                className="p-2 text-purple-600 hover:bg-purple-100 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-sm font-medium flex items-center transition-colors duration-200"
                title="Duplicate question"
              >
                <FiCopy className="mr-1" /> Duplicate
              </button>
              <button
                onClick={(e) => { e.stopPropagation(); toggleRequired(); }}
                className={`p-2 ${question.isRequired ? 'text-purple-600' : 'text-gray-600'} hover:bg-purple-100 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-sm font-medium flex items-center transition-colors duration-200`}
                title={question.isRequired ? "Make optional" : "Make required"}
              >
                {question.isRequired ? <FiToggleRight className="mr-1" /> : <FiToggleLeft className="mr-1" />} Required
              </button>
              <button
                onClick={(e) => { e.stopPropagation(); onDelete(); }}
                className="p-2 text-red-600 hover:bg-red-100 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 text-sm font-medium flex items-center transition-colors duration-200"
                title="Delete question"
              >
                <FiTrash2 className="mr-1" /> Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionItem;