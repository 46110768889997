import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimatedText from './AnimatedText';
import SubscribeModal from './SubcribeModel';
import { useAuth } from '../api/AuthContext';
import ReachOutModal from './ReachOutModal';

const ChatWindow = ({ messages, input, handleInputChange, handleSend, handleEnter, msgEnd, isTyping, handleClone, closeChat }) => {
    const navigate = useNavigate();
    const chatEndRef = useRef(null);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const { user } = useAuth();

    useEffect(() => {
        scrollToBottom();
    }, [messages, isTyping]);

    const scrollToBottom = () => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleAnimationComplete = () => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const handleBookSession = () => {
        if (user.data.user.reach_out_session === 0) {
            setIsInfoModalOpen(true);
        } else {
            window.open('https://calendly.com/bookaconsultation-bodc/relevant-reach-product-demo-clone?preview_source=et_card&month=2024-08', '_blank');
        }
    };

    const renderButtons = (message) => {
        const buttons = [];

        if (message.isBot) {
            const surveyRelatedKeywords = [
                'how to create survey',
                'create survey',
                'create a survey',
                'start survey',
                'make a survey',
                'build a survey',
                'survey creation',
                'launch survey',
                'begin survey'
            ];

            if (surveyRelatedKeywords.some(keyword => message.text.toLowerCase().includes(keyword))) {
                buttons.push(
                    <button
                        key="create_survey"
                        onClick={() => navigate('/create-survey')}
                        className="bg-purple-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-purple-600 mt-2"
                    >
                        Create Survey
                    </button>
                );
            }

            const bookingRelatedKeywords = [
                'book session',
                'book a session',
                'schedule session',
                'schedule a session',
                'reach out',
                'book a time',
                'consultation',
                'product demo',
                'demo session',
                'schedule meeting'
            ];

            if (bookingRelatedKeywords.some(keyword => message.text.toLowerCase().includes(keyword))) {
                buttons.push(
                    <button
                        key="book_session"
                        onClick={handleBookSession} // Update to use handleBookSession
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-blue-600 mt-2"
                    >
                        Book Session
                    </button>
                );
            }

            const supportRelatedKeywords = [
                'contact support',
                'contact us',
                'reach out via email',
                'email support',
                'support team',
                'customer support',
                'get help',
                'technical support',
                'help desk'
            ];

            if (supportRelatedKeywords.some(keyword => message.text.toLowerCase().includes(keyword))) {
                buttons.push(
                    <button
                        key="contact_support"
                        onClick={() => window.location.href = 'mailto:info@relevantreact.io'}
                        className="bg-red-500 text-white px-4 py-2 rounded-lg inline-block hover:bg-red-600 mt-2"
                    >
                        Contact Support
                    </button>
                );
            }

            const subscribeRelatedKeywords = [
                'upgrade',
                'subscribe',
                'upgrading',
                'subscription',
                'Pricing page',
                'premium',
                'purchase credits',
                'buy credits',
                'buy subscription',
                'subscribe to premium',
                "purchase"
            ];

            if (subscribeRelatedKeywords.some(keyword => message.text.toLowerCase().includes(keyword))) {
                buttons.push(
                    <button
                        key="subscribe"
                        onClick={() => setIsModelOpen(true)}
                        className="bg-yellow-500 text-white px-4 py-2 rounded-xl inline-block hover:bg-yellow-600 mt-2"
                    >
                        Upgrade/Subscribe
                    </button>
                );
            }
        }

        return buttons;
    };

    return (
        <div className="fixed bottom-10 right-6 z-50 bg-white font-poppins rounded-2xl shadow-xl w-96 sm:w-108 md:w-120 lg:w-128 flex flex-col h-[600px] lg:h-[600px] overflow-hidden">
            <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white p-4 rounded-t-2xl flex justify-between items-center">
                <div className="flex items-center">
                    <div className="rounded-full bg-white p-1 mr-3">
                        <img src="/logo512.png" alt="ChatBot" className="w-7 h-7" />
                    </div>
                    <div className="text-left">
                        <h3 className="font-bold text-lg">Relevant Reach AI</h3>
                        <p className="text-xs text-purple-200">Online</p>
                    </div>
                </div>
                <button onClick={closeChat} className="text-white hover:bg-white hover:bg-opacity-20 rounded-full p-2 transition-colors duration-200">
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>
            <div className="flex-1 overflow-y-auto p-4 bg-gray-50 text-black">
                {messages.map((message, index) => (
                    <div key={index} className={`mb-4 ${message.isBot ? 'self-start' : 'self-end'}`}>
                        <div className="flex items-center mb-1">
                            {message.isBot && (
                                <div className="rounded-full bg-purple-600 w-6 h-6 mr-2 flex items-center justify-center overflow-hidden">
                                    <img src="/logo512.png" alt="ChatBot" className="w-full h-full object-cover" />
                                </div>
                            )}
                            <span className={`${message.isBot ? 'text-purple-600' : 'text-gray-600'} text-xs font-semibold`}>
                                {message.isBot ? 'RR AI' : 'You'}
                            </span>
                        </div>
                        <div className={`p-3 rounded-xl shadow-sm ${message.isBot ? 'bg-white' : 'bg-gradient-to-r from-purple-500 to-indigo-500'}`}>
                            <AnimatedText
                                text={message.text.trim()}
                                className={message.isBot ? 'text-black' : 'text-white'}
                                onAnimationComplete={handleAnimationComplete}
                            />
                            <div className="mt-3 space-x-2">
                                {renderButtons(message)}
                            </div>
                        </div>
                    </div>
                ))}
                {isTyping && (
                    <div className="mt-2 self-start flex items-start">
                        <div className="rounded-full bg-purple-100 p-1 mr-2">
                            <img src="/logo512.png" alt="ChatBot" className="w-5 h-5" />
                        </div>
                        <div className="bg-white p-3 rounded-xl shadow-sm">
                            <div className="flex space-x-2">
                                <div className="w-2 h-2 bg-purple-600 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                                <div className="w-2 h-2 bg-purple-600 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                                <div className="w-2 h-2 bg-purple-600 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={chatEndRef} />
            </div>
            <div className="bg-white p-4 border-t border-gray-200 flex items-center rounded-b-2xl">
                <input
                    placeholder="Type your message"
                    value={input}
                    onKeyDown={handleEnter}
                    onChange={handleInputChange}
                    className="bg-gray-100 flex-grow p-3 rounded-full border border-gray-300 outline-none resize-none text-sm focus:ring-2 focus:ring-purple-500 transition-all duration-200"
                />
                <button onClick={handleSend} className="ml-3 p-3 bg-gradient-to-r from-purple-500 to-indigo-500 text-white rounded-full hover:from-purple-600 hover:to-indigo-600 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                    </svg>
                </button>
            </div>

            {isModelOpen && (
                <SubscribeModal onClose={(value) => setIsModelOpen(value)} />
            )}

            {isInfoModalOpen && (
                <ReachOutModal
                    isOpen={isInfoModalOpen}
                    onClose={() => setIsInfoModalOpen(false)}
                    message="You already used your session. Upgrade to Impact for more sessions or pay as you go."
                    link="https://calendly.com/event_types/171691363/edit?return_to=%2Fevent_types%2Fuser%2Fme"
                />
            )}
        </div>
    );
};

export default ChatWindow;