import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App, { AppRouter } from './App';
import reportWebVitals from './reportWebVitals';
import { Theme, Flex, Spinner } from '@radix-ui/themes';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root')); // Ensure this matches your HTML

root.render(
  <React.StrictMode>
    <ToastContainer />
    <RouterProvider router={AppRouter} />  {/* Correctly render the AppRouter */}
  </React.StrictMode>
);

// Optional: Measure performance
reportWebVitals();
