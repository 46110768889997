import React, { useEffect, useRef, useState } from 'react';
import ChatWindow from './ChatWindow';
import ChatToggleButton from './ChatToggleButton';
import { sendMsgToAssistant } from '../Utils/chatGPT';
import { useAuth } from '../api/AuthContext';

const AIbot = ({ openAfterFive = false }) => {
  const [input, setInput] = useState("");
  const msgEnd = useRef(null);
  const { user } = useAuth();

  const userName = user?.data?.user?.name || 'User';
  const userEmail = user?.data?.user?.email || 'No email provided';
  const userPlan = user?.data?.user?.is_subscribed ? user.data.user.current_package : "No Plan";
  const userCredits = user?.data?.user?.credits || "No Credits";
  const reachOutCredits = user?.data?.user?.reach_out_session || "No Credits";

  // Generate random chatId
  const chatId = Math.random().toString(36).substring(2, 15);

  const initialContext = {
    role: "system",
    content: `
    You are a specialized assistant for Relevant Reach, focused on guiding users in creating surveys to understand their target audience. Your responses should remain within the domain of Marketing and Relevant Reach-related queries. Start by offering help and proceed based on the user's familiarity with their target audience.
  
    User Details: ${userName}, ${userEmail}
    User's Current Plan:  ${userPlan}
    User's Reach Out Credits: ${reachOutCredits}
  
     Conversation Structure:
  
    1. Initial Offer: Start with "Do you need help with creating your survey to better understand your target audience?"
    2. Audience Familiarity:
       - If they know their target audience, ask: "What additional information would you like to gather about your audience, such as hobbies, interests, or income?"
       - If they don't know their target audience, offer: "Would you like me to suggest basic survey questions to help identify your target audience, or would you prefer to book a time for a Reach Out session using your credits?"
    3. Survey Assistance: Offer to assist in forming specific survey questions based on the information provided about their target audience.
    4. Next Steps: Always provide a clear next step based on their response, such as guiding them to book a session or suggesting survey improvements.
  
     General Queries:
  
    - Purpose of Credits: "Credits are used for reach out sessions. You can purchase more credits as needed."
    - Survey Answer Visibility: "To see survey answers, you need to create answer options in your survey. If you need help with this, book a time with us for 1 credit!"
    - Starting a Survey: "To start a survey:
       1. Click 'Create Survey'.
       2. Choose your survey type (e.g., multiple choice, rating scale, open text).
       3. Add questions and answer options.
       4. Customize design and branding.
       5. Preview and launch your survey."
    - Canceling Subscription: "Cancel your subscription in the Subscription section. Detailed steps are available there."
    - Common Survey Questions:
       - If they know their audience: "Great! What more would you like to know about them?"
       - If unsure: "Who do you think your current audience might be?" Adjust further questions based on their answer.
  
    - Managing Reach Out Sessions:
       - If user has credits: "You can use your Reach Out 'Live' 1:1 Session as part of your subscription plan. Would you like to schedule a call?
       - If user has already used their session: "You have already used your session for this month. You can upgrade to the Impact plan to get up to 3 Reach Out sessions per month, or choose a pay-as-you-go option. Would you like to upgrade or pay as you go?" Provide the relevant links: [UPGRADE_LINK] or [PAY_AS_YOU_GO_LINK].
  
    Upgrade to Amplify (for Connect & Engage Plan Users):
  
    - If survey responses exceed 10 while on the dashboard:
  
      - Offer: "Would you like content suggestions tailored to your audience using your dashboard data?"
        - If they agree and have the Connect & Engage plan: "You are on Connect & Engage. Would you like to upgrade for a 7-day free trial?"
        - If on Amplify: "Let me analyze your dashboard data to suggest content targeting one of your audience segments."
        - If they decline: Offer to show an example of what the content might look like and proceed based on their product or service.
    Create 1 blog article (as an example) that incorporates the top SEO terms around their dashboard data with the following:
    - 1 audience.
    - 1 income level.
    - 1 hobby.
    - 1 interest.
    - 1 product or service it is interested in.
  
    Note: Return a key with the message to show buttons if needed. Use the user's current plan details and the current session context for personalization.`
  };


  const [obj, setObj] = useState([initialContext]);
  const [messages, setMessages] = useState([{ id: 0, text: openAfterFive ? "Do you need help with creating your survey to better understand your target audience?" : "Welcome to Relevant Reach. How can I help you today?", isBot: true }]);
  const [isOpen, setIsOpen] = useState(false);

  const [isTyping, setIsTyping] = useState(false);
  const [lastMessageId, setLastMessageId] = useState(0);
  const [isFirstMessage, setIsFirstMessage] = useState(true);

  useEffect(() => {
    if (msgEnd.current) {
      msgEnd.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isTyping]);

  useEffect(() => {
    if (openAfterFive) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [openAfterFive]);


  const [threadId, setThreadId] = useState(null);



  const handleSend = async () => {
    if (input.trim() === '') return;

    const newUserMessage = { id: lastMessageId + 1, text: input, isBot: false };
    const newMessages = [...messages, newUserMessage];
    const updatedObj = [...obj, { role: "user", content: input }];
    setMessages(newMessages);
    setObj(updatedObj);
    setInput('');
    setLastMessageId(prev => prev + 1);
    setIsTyping(true);
    try {
      const context = isFirstMessage ? [initialContext, { role: "assistant", content: openAfterFive ? "Do you need help with creating your survey to better understand your target audience?" : "Welcome to Relevant Reach. How can I help you today?" }, { role: "user", content: input }] : updatedObj;
      setIsFirstMessage(false);
      const res = await sendMsgToAssistant({ message: input, threadId: threadId, type: "dashboard" });
      console.log(res);
      if (typeof res === "object" && res.content && res.threadId) {
        const newBotMessage = { id: lastMessageId + 3, text: res.content, isBot: true };
        setMessages(prev => [...prev, newBotMessage]);
        setObj(prev => [...prev, { role: "assistant", content: res.content }]);
        setLastMessageId(prev => prev + 3);
        setThreadId(res.threadId);
      } else {
        console.error("Unexpected response structure:", res);
        const errorMessage = { id: lastMessageId + 3, text: "Sorry, I couldn't generate a response at the moment.", isBot: true };
        setMessages(prev => [...prev, errorMessage]);
        setLastMessageId(prev => prev + 3);
      }
    } catch (error) {
      console.error("Error in handleSend:", error);
      const errorMessage = { id: lastMessageId + 3, text: "Sorry, there was an error processing your request.", isBot: true };
      setMessages(prev => [...prev, errorMessage]);
      setLastMessageId(prev => prev + 3);
    } finally {
      setIsTyping(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') handleSend();
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleClone = () => {
    const clonedMessages = [...messages];
    const newUserMessage = { id: lastMessageId + 1, text: input, isBot: false };
    clonedMessages.push(newUserMessage);
    setMessages(clonedMessages);
    setLastMessageId(prev => prev + 1);
  };

  const closeChat = () => {
    setIsOpen(false);
  };

  return (
    <div className='rounded-3xl'>
      {!isOpen && <ChatToggleButton isOpen={isOpen} toggleChat={toggleChat} />}
      {isOpen && (
        <ChatWindow
          messages={messages}
          input={input}
          handleInputChange={handleInputChange}
          handleSend={handleSend}
          handleEnter={handleEnter}
          msgEnd={msgEnd}
          isTyping={isTyping}
          handleClone={handleClone}
          closeChat={closeChat}
          lastMessageId={lastMessageId}
        />
      )}
    </div>
  );
};

export default AIbot;
