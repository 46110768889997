import React, { createContext, useState, useEffect } from 'react';
import { currentUser, verifyOTPApi, login as loginUser, logout as logoutUser, } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Components/Spinner';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            try {

                const userData = await currentUser();

                if (!userData.error) {
                    setUser(userData);
                } else {
                    setUser(null);
                }
            } catch (error) {

                setUser(null);
            } finally {
                setLoading(false);
            }
        };
        checkUser();
    }, []);



    // cretae a function to update the user
    const updateUser = async (user) => {
        try {

            const userData = await currentUser();

            if (!userData.error) {
                setUser(userData);
            } else {
                setUser(null);
            }
        } catch (error) {

            setUser(null);
        } finally {
            setLoading(false);
        }

    };

    const login = async (email, password) => {
        try {
            const data = await loginUser(email, password);
            return data;
        } catch (error) {
            throw error;
        }
    };


    const verifyOTP = async (email, password, otp) => {
        try {
            const data = await verifyOTPApi(email, password, otp);
            console.log("I am here at AuthContext.js");
            console.log(data);
            if (!data.error) {
                console.log("I am here in AuthContext.js");
                console.log(data)
                console.log(data.authorisation.token);
                localStorage.setItem("tk", data.authorisation.token);
                const userData = await currentUser();
                setUser(userData);
                return data;
            }
            throw new Error(data.message);
        } catch (error) {
            throw error;
        }
    };





    const logout = async () => {
        await logoutUser();
        localStorage.removeItem('tk');
        setUser(null);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading, verifyOTP }}>
            {!loading ? children : <Spinner />}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
