import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { motion, AnimatePresence } from 'framer-motion';

const animatedMessagesMap = new Map();

const AnimatedText = ({ text, className }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [isComplete, setComplete] = useState(false);

    useEffect(() => {
        if (isComplete) return;

        if (animatedMessagesMap.has(text)) {
            setDisplayedText(text);
            setComplete(true);
        } else {
            let currentText = '';
            let index = 0;

            const interval = setInterval(() => {
                currentText += text[index];
                setDisplayedText(currentText);
                index += 1;

                if (index >= text.length) {
                    clearInterval(interval);
                    setComplete(true);
                    animatedMessagesMap.set(text, true);
                }
            }, 10);

            return () => clearInterval(interval);
        }
    }, [text, isComplete]);

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
            >
                <ReactMarkdown
                    className={`prose prose-sm max-w-none text-left leading-relaxed ${className}`}
                    children={displayedText}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        p: ({ node, ...props }) => <p className={`mb-2 ${className}`} {...props} />,
                        a: ({ node, ...props }) => <a className={`text-purple-600 hover:text-purple-800 transition-colors duration-200 ${className}`} {...props} />,
                        code: ({ node, inline, ...props }) => (
                            <code className={`${inline ? 'bg-gray-100 rounded px-1' : 'block bg-gray-100 p-2 rounded-md my-2'} ${className}`} {...props} />
                        ),
                        ul: ({ node, ...props }) => <ul className={`list-disc pl-5 mb-2 ${className}`} {...props} />,
                        ol: ({ node, ...props }) => <ol className={`list-decimal pl-5 mb-2 ${className}`} {...props} />,
                    }}
                />
            </motion.div>
        </AnimatePresence>
    );
};

export default AnimatedText;
