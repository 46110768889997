import React, { createContext, useState, useMemo } from 'react';

export const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
    const [surveyData, setSurveyData] = useState([]);
    const [lastFetchTime, setLastFetchTime] = useState(null);

    const value = useMemo(() => ({
        surveyData,
        setSurveyData,
        lastFetchTime,
        setLastFetchTime
    }), [surveyData, lastFetchTime]);

    return (
        <SurveyContext.Provider value={value}>
            {children}
        </SurveyContext.Provider>
    );
};