'use client'

import React, { useState } from 'react'
import { Copy, Send, ChevronDown, Loader2 } from 'lucide-react'
import axiosApi from '../api/axiosApi'
import { useNavigate } from 'react-router-dom'
import { Button } from "../components1/ui/button"
import { Input } from "../components1/ui/input"
import { Card, CardContent, CardHeader, CardTitle } from "../components1/ui/card"
import { Progress } from "../components1/ui/progress"
import { Badge } from "../components1/ui/badge"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components1/ui/tooltip"
import { motion, AnimatePresence } from 'framer-motion'
import { ClipLoader } from 'react-spinners'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components1/ui/tabs"

const categories = [
    { id: 'black-friday', name: 'Black Friday' },
    { id: 'christmas', name: 'Christmas' },
    { id: 'valentines-day', name: "Valentine's Day" },
    { id: 'mothers-day', name: "Mother's Day" },
    { id: 'fathers-day', name: "Father's Day" },
    { id: '4th-of-july', name: "4th of July" },
    { id: 'abandoned-cart', name: "Abandoned cart" },
    { id: 'welcome', name: "Welcome" },
    { id: 'win-back', name: "Win-back" },
    { id: 'sale', name: "Sale" },
    { id: 'new-collection', name: "New collection" },
    { id: 'limited-time-offer', name: "Limited-time offer" },
    { id: 'cyber-monday', name: "Cyber Monday" },
    { id: 'thanksgiving', name: "Thanksgiving" },
    { id: 'giving-tuesday', name: "Giving Tuesday" },
]

const subjectLines = {
    'black-friday': [
        { title: "Don't miss out our BOGO sale!", score: 92 },
        { title: "Black Sale. New arrivals 30% off!", score: 100 },
        { title: "🎁 25% Black Friday Code: FRIDAY", score: 92 },
        { title: "Black Friday is back! 🛍️ Shop now!", score: 92 },
        { title: "🚨 BLACK SALE! Bestsellers 45% off!", score: 92 },
    ],
    'christmas': [
        { title: "Merry Christmas! 🎄 30% off everything!", score: 100 },
        { title: "🎁 Christmas Sale! New arrivals 40% off!", score: 92 },
        { title: "Last minute Christmas shopping? We've got you covered!", score: 92 },
        { title: "🎅 Ho Ho Ho! Christmas Sale is here!", score: 92 },
        { title: "🎄 Christmas Countdown: 50% off everything!", score: 92 },
    ],
    'valentines-day': [
        { title: "Valentine's Day Sale! 20% off all gifts!", score: 100 },
        { title: "❤️ Love is in the air! Valentine's Day Sale!", score: 92 },
        { title: "🌹 Roses are red, violets are blue. Valentine's Day Sale!", score: 92 },
        { title: "Valentine's Day is here! Get 30% off your order!", score: 92 },
        { title: "💘 Valentine's Day Special: 40% off all gifts!", score: 92 },
    ],
    'mothers-day': [
        { title: "🌷 Happy Mother's Day! 25% off all gifts!", score: 100 },
        { title: "🎁 Mother's Day Sale! New arrivals 35% off!", score: 92 },
        { title: "Last minute Mother's Day shopping? We've got you covered!", score: 92 },
        { title: "👩‍👧‍👦 Celebrate Mother's Day with 30% off!", score: 92 },
        { title: "🌹 Mother's Day Special: 45% off all gifts!", score: 92 },
    ],
    'fathers-day': [
        { title: "👔 Happy Father's Day! 20% off all gifts!", score: 100 },
        { title: "🎁 Father's Day Sale! New arrivals 30% off!", score: 92 },
        { title: "Last minute Father's Day shopping? We've got you covered!", score: 92 },
        { title: "👨‍👧‍👦 Celebrate Father's Day with 25% off!", score: 92 },
        { title: "🍺 Father's Day Special: 40% off all gifts!", score: 92 },
    ],
    '4th-of-july': [
        { title: "🇺🇸 Happy 4th of July! 25% off everything!", score: 100 },
        { title: "🎆 4th of July Sale! New arrivals 35% off!", score: 92 },
        { title: "Celebrate Independence Day with 30% off!", score: 92 },
        { title: "🎇 4th of July Special: 40% off all items!", score: 92 },
        { title: "🍔 4th of July BBQ: 50% off all orders!", score: 92 },
    ],
    'abandoned-cart': [
        { title: "🛒 You forgot something! Here's 10% off your order!", score: 100 },
        { title: "🛍️ Your items are waiting! Get 15% off your order!", score: 92 },
        { title: "🛒 Don't miss out! Here's 20% off your order!", score: 92 },
        { title: "🛍️ Your cart is lonely! Get 25% off your order!", score: 92 },
        { title: "🛒 Come back and save! Here's 30% off your order!", score: 92 },
    ],
    'welcome': [
        { title: "👋 Welcome to our store! Here's 10% off your first order!", score: 100 },
        { title: "🎉 You're here! Get 15% off your first order!", score: 92 },
        { title: "👋 We're glad you're here! Here's 20% off your first order!", score: 92 },
        { title: "🎉 Welcome to the family! Get 25% off your first order!", score: 92 },
        { title: "👋 We're so happy you're here! Here's 30% off your first order!", score: 92 },
    ],
    'win-back': [
        { title: "🔙 We miss you! Here's 10% off your next order!", score: 100 },
        { title: "🔙 Come back! Get 15% off your next order!", score: 92 },
        { title: "🔙 We want you back! Here's 20% off your next order!", score: 92 },
        { title: "🔙 We're sorry! Get 25% off your next order!", score: 92 },
        { title: "🔙 We messed up! Here's 30% off your next order!", score: 92 },
    ],
    'sale': [
        { title: "🎉 Sale! 20% off everything!", score: 100 },
        { title: "🎁 Sale! New arrivals 30% off!", score: 92 },
        { title: "🛍️ Sale! Get 40% off your order!", score: 92 },
        { title: "🎉 Sale! 50% off all items!", score: 92 },
        { title: "🎁 Sale! 60% off all orders!", score: 92 },
    ],
    'new-collection': [
        { title: "🎉 New collection! 20% off everything!", score: 100 },
        { title: "🎁 New collection! Get 30% off your order!", score: 92 },
        { title: "🛍️ New collection! 40% off all items!", score: 92 },
        { title: "🎉 New collection! 50% off all orders!", score: 92 },
        { title: "🎁 New collection! 60% off everything!", score: 92 },
    ],
    'limited-time-offer': [
        { title: "🎉 Limited time offer! 20% off everything!", score: 100 },
        { title: "🎁 Limited time offer! Get 30% off your order!", score: 92 },
        { title: "🛍️ Limited time offer! 40% off all items!", score: 92 },
        { title: "🎉 Limited time offer! 50% off all orders!", score: 92 },
        { title: "🎁 Limited time offer! 60% off everything!", score: 92 },
    ],
    'cyber-monday': [
        { title: "🎉 Cyber Monday! 20% off everything!", score: 100 },
        { title: "🎁 Cyber Monday! Get 30% off your order!", score: 92 },
        { title: "🛍️ Cyber Monday! 40% off all items!", score: 92 },
        { title: "🎉 Cyber Monday! 50% off all orders!", score: 92 },
        { title: "🎁 Cyber Monday! 60% off everything!", score: 92 },
    ],
    'thanksgiving': [
        { title: "🦃 Happy Thanksgiving! 20% off everything!", score: 100 },
        { title: "🎁 Thanksgiving Sale! Get 30% off your order!", score: 92 },
        { title: "🛍️ Thanksgiving Special! 40% off all items!", score: 92 },
        { title: "🦃 Thanksgiving Day! 50% off all orders!", score: 92 },
        { title: "🎁 Thanksgiving Sale! 60% off everything!", score: 92 },
    ],
    'giving-tuesday': [
        { title: "🎁 Giving Tuesday! 20% off everything!", score: 100 },
        { title: "🛍️ Giving Tuesday! Get 30% off your order!", score: 92 },
        { title: "🎁 Giving Tuesday! 40% off all items!", score: 92 },
        { title: "🛍️ Giving Tuesday! 50% off all orders!", score: 92 },
        { title: "🎁 Giving Tuesday! 60% off everything!", score: 92 },
    ],
}

const EmailSubjectLineAnalysis = ({ data }) => {
    if (!data || typeof data !== 'object') {
        return <div>No analysis data available.</div>;
    }

    const {
        email_subject,
        score,
        detailed_analysis,
        explanation,
        subject_line_preview,
        suggested_subject_lines,
        enhanced_alternative_subject_lines
    } = data;

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Card className="mt-8 bg-white/80 backdrop-blur-sm shadow-xl">
                <CardHeader>
                    <CardTitle className="text-2xl font-bold text-purple-800">Analysis Results</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="flex flex-col md:flex-row items-center justify-between mb-6">
                        <div className="flex items-center mb-4 md:mb-0">
                            <div className="mr-4 relative">
                                <Progress value={score} className="w-24 h-24 rounded-full" />
                                <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-xl text-white">{score}%</p>
                            </div>
                            <div>
                                <h3 className="text-xl font-bold text-purple-800">{email_subject}</h3>
                                <p className="text-sm text-gray-500">Your subject line score</p>
                            </div>
                        </div>
                        <Badge variant={score >= 80 ? "success" : score >= 60 ? "warning" : "destructive"} className="text-lg py-2 px-4">
                            {score >= 80 ? "Excellent" : score >= 60 ? "Good" : "Needs Improvement"}
                        </Badge>
                    </div>

                    <div className="space-y-8">
                        <AnalysisSection title="Length Analysis">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <LengthAnalysis title="Character count" value={detailed_analysis.length.character_count} maxValue={50} idealRange={detailed_analysis.length.ideal_range} assessment={detailed_analysis.length.assessment} />
                                <LengthAnalysis title="Word count" value={detailed_analysis.word_count.count} maxValue={10} idealRange={detailed_analysis.word_count.ideal_range} assessment={detailed_analysis.word_count.assessment} />
                            </div>
                        </AnalysisSection>

                        <AnalysisSection title="Wording Analysis">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <WordingAnalysis title="Helpful words" data={detailed_analysis.wording.helpful_words} type="helpful" feedback={detailed_analysis.wording.feedback.helpful_words} />
                                <WordingAnalysis title="Negative words" data={detailed_analysis.wording.negative_words} type="negative" feedback={detailed_analysis.wording.feedback.negative_words} />
                            </div>
                        </AnalysisSection>

                        <AnalysisSection title="Scannability">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <ScannabilityItem title="Use of Numbers" value={detailed_analysis.scannability.use_of_numbers} feedback={detailed_analysis.scannability.feedback_numbers} />
                                <ScannabilityItem title="Capitalization Style" value={detailed_analysis.scannability.capitalization_style} feedback={detailed_analysis.scannability.feedback_capitalization} />
                            </div>
                        </AnalysisSection>

                        <AnalysisSection title="Additional Factors">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <AdditionalFactor title="Personalization" presence={detailed_analysis.personalization.presence} feedback={detailed_analysis.personalization.feedback} />
                                <AdditionalFactor title="Urgency and Scarcity" presence={detailed_analysis.urgency_and_scarcity.presence} feedback={detailed_analysis.urgency_and_scarcity.feedback} />
                                <AdditionalFactor title="Emotional Appeal" presence={detailed_analysis.emotional_appeal.presence} feedback={detailed_analysis.emotional_appeal.feedback} />
                                <AdditionalFactor title="Spam Triggers" assessment={detailed_analysis.avoidance_of_spam_triggers.assessment} feedback={detailed_analysis.avoidance_of_spam_triggers.feedback} />
                                <AdditionalFactor title="Originality and Creativity" assessment={detailed_analysis.originality_and_creativity.assessment} feedback={detailed_analysis.originality_and_creativity.feedback} />
                            </div>
                        </AnalysisSection>

                        <AnalysisSection title="Explanation">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <ExplanationList title="Strengths" items={explanation.strengths} />
                                <ExplanationList title="Areas for Improvement" items={explanation.areas_for_improvement} />
                            </div>
                        </AnalysisSection>


                        <AnalysisSection title="Suggested Subject Lines">
                            <SuggestedLines lines={suggested_subject_lines} />
                        </AnalysisSection>

                        <AnalysisSection title="Enhanced Alternative Subject Lines">
                            <SuggestedLines lines={enhanced_alternative_subject_lines} />
                        </AnalysisSection>
                    </div>
                </CardContent>
            </Card>
        </motion.div>
    );
};

const AnalysisSection = ({ title, children }) => (
    <div className="bg-white/50 backdrop-blur-sm rounded-lg p-4 shadow-md">
        <h3 className="text-xl font-bold text-purple-800 mb-4">{title}</h3>
        {children}
    </div>
);

const LengthAnalysis = ({ title, value, maxValue, idealRange, assessment }) => (
    <div className="bg-white/80 rounded-lg p-4 shadow-sm">
        <h4 className="text-lg font-semibold mb-2 text-purple-700">{title}</h4>
        <Progress value={(value / maxValue) * 100} className="h-2 mb-2" />
        <p className="text-sm text-gray-600">{value} / {maxValue}</p>
        <p className="text-sm text-gray-600">Ideal range: {idealRange}</p>
        <p className="text-sm text-gray-600 mt-2">{assessment}</p>
    </div>
);

const WordingAnalysis = ({ title, data, type, feedback }) => (
    <div className="bg-white/80 rounded-lg p-4 shadow-sm">
        <h4 className="text-lg font-semibold mb-2 text-purple-700">{title}</h4>
        {data.length > 0 ? (
            <div className="flex flex-wrap gap-2 mb-2">
                {data.map((word, index) => (
                    <Badge key={index} variant={type === 'helpful' ? 'success' : 'destructive'}>{word}</Badge>
                ))}
            </div>
        ) : (
            <p className="text-sm text-gray-600 mb-2">No {type} words found.</p>
        )}
        <p className="text-sm text-gray-600">{feedback}</p>
    </div>
);

const ScannabilityItem = ({ title, value, feedback }) => (
    <div className="bg-white/80 rounded-lg p-4 shadow-sm">
        <h4 className="text-lg font-semibold mb-2 text-purple-700">{title}</h4>
        <Badge variant="success" className="mb-2">{value}</Badge>
        <p className="text-sm text-gray-600">{feedback}</p>
    </div>
);

const AdditionalFactor = ({ title, presence, assessment, feedback }) => (
    <div className="bg-white/80 rounded-lg p-4 shadow-sm">
        <h4 className="text-lg font-semibold mb-2 text-purple-700">{title}</h4>
        {presence !== undefined && <Badge variant={presence ? "success" : "warning"} className="mb-2">{presence ? "Present" : "Not Present"}</Badge>}
        {assessment && <Badge variant="success" className="mb-2">{assessment}</Badge>}
        <p className="text-sm text-gray-600">{feedback}</p>
    </div>
);

const ExplanationList = ({ title, items }) => (
    <div className="bg-white/80 rounded-lg p-4 shadow-sm">
        <h4 className="text-lg font-semibold mb-2 text-purple-700">{title}</h4>
        <ul className="list-disc list-inside">
            {items.map((item, index) => (
                <li key={index} className="text-sm text-gray-600">{item}</li>
            ))}
        </ul>
    </div>
);

const PreviewItem = ({ title, value }) => (
    <div className="bg-white/80 rounded-lg p-4 shadow-sm">
        <h4 className="text-lg font-semibold mb-2 text-purple-700">{title}</h4>
        <p className="text-sm bg-gray-100 p-2 rounded">{value}</p>
    </div>
);

const SuggestedLines = ({ lines }) => (
    <div className="bg-white/80 rounded-lg p-4 shadow-sm">
        <ul className="list-disc list-inside">
            {lines.map((line, index) => (
                <li key={index} className="text-sm text-gray-600 mb-2">{line}</li>
            ))}
        </ul>
    </div>
);

export default function EmailSubjectLineTester() {
    const [subjectLine, setSubjectLine] = useState('')
    const [activeCategory, setActiveCategory] = useState('black-friday')
    const [analysisResult, setAnalysisResult] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [activeTab, setActiveTab] = useState('tester')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setError(null)

        try {
            const response = await axiosApi.post('/api/subjectLineCompletion', { subject: subjectLine })
            setAnalysisResult(response.data.data)
        } catch (err) {
            setError('An error occurred while analyzing the subject line. Please try again.')
            console.error('Error analyzing subject line:', err)
        } finally {
            setIsLoading(false)
        }
    }

    const emailStats = [
        { name: 'Open Rate', value: 22.86, industry: 'E-commerce' },
        { name: 'Click-Through Rate', value: 2.78, industry: 'E-commerce' },
        { name: 'Conversion Rate', value: 15.11, industry: 'E-commerce' },
        { name: 'Bounce Rate', value: 0.63, industry: 'E-commerce' },
    ];

    const subjectLineTips = [
        "Keep it short and sweet (30-50 characters)",
        "Use action-oriented verbs",
        "Create a sense of urgency",
        "Personalize when possible",
        "Ask intriguing questions",
        "Use numbers and lists",
        "Avoid spam trigger words",
        "Test emojis (but don't overdo it)",
    ];

    const industryBenchmarks = [
        { industry: 'Retail', openRate: 18.39, clickRate: 2.33 },
        { industry: 'Technology', openRate: 21.29, clickRate: 2.45 },
        { industry: 'Travel', openRate: 20.44, clickRate: 2.16 },
        { industry: 'Healthcare', openRate: 21.72, clickRate: 2.49 },
        { industry: 'Finance', openRate: 24.11, clickRate: 2.72 },
    ];

    return (
        <main className="min-h-screen bg-gradient-to-r from-purple-100 via-pink-100 to-indigo-100 p-4 md:p-8">
            <div className="max-w-6xl mx-auto">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Card className="mb-8 bg-white/80 backdrop-blur-sm shadow-xl">
                        <CardHeader>
                            <CardTitle className="text-3xl font-bold text-center text-purple-800">Email Subject Line Tester</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-xl mb-6 text-center text-gray-600">
                                Boost your email open rates: analyze and improve your subject lines before sending your next campaign.
                            </p>

                            <form onSubmit={handleSubmit} className="flex flex-col md:flex-row gap-2">
                                <Input
                                    type="text"
                                    placeholder="Enter your subject line"
                                    value={subjectLine}
                                    onChange={(e) => setSubjectLine(e.target.value)}
                                    className="flex-grow text-lg"
                                />
                                <Button className="text-white bg-purple-600 hover:bg-purple-700 transition-colors duration-200" type="submit" disabled={isLoading}>
                                    {isLoading ? (
                                        <ClipLoader color="#ffffff" size={20} />
                                    ) : (
                                        <>
                                            <Send className="mr-2 h-4 w-4 text-white" />
                                            Test now
                                        </>
                                    )}
                                </Button>
                            </form>




                        </CardContent>
                    </Card>
                </motion.div>

                {analysisResult ? (
                    <EmailSubjectLineAnalysis data={analysisResult} />
                ) : (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <Card className="mt-8 bg-white/80 backdrop-blur-sm shadow-xl">
                            <CardHeader>
                                <CardTitle className="text-2xl font-bold text-purple-800">Subject Line Best Practices</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {subjectLineTips.map((tip, index) => (
                                        <div key={index} className="flex items-start">
                                            <div className="flex-shrink-0 h-6 w-6 rounded-full bg-purple-500 flex items-center justify-center text-white font-bold mr-3">
                                                {index + 1}
                                            </div>
                                            <p className="text-gray-700">{tip}</p>
                                        </div>
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    </motion.div>
                )}

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <Card className="mt-8 bg-white/80 backdrop-blur-sm shadow-xl">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold text-purple-800">Subject Line Inspiration</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flex flex-wrap gap-2 mb-6">
                                {categories.map(category => (
                                    <Button
                                        key={category.id}
                                        variant={activeCategory === category.id ? "default" : "outline"}
                                        onClick={() => setActiveCategory(category.id)}
                                        className={`rounded-full transition-colors duration-200 ${activeCategory === category.id ? 'text-white' : 'text-black'}`}
                                    >
                                        {category.name}
                                    </Button>
                                ))}
                            </div>

                            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                                <table className="w-full">
                                    <thead>
                                        <tr className="bg-purple-100">
                                            <th className="px-6 py-3 text-left text-xs font-medium text-purple-800 uppercase tracking-wider">Subject Line</th>
                                            <th className="px-6 py-3 text-center text-xs font-medium text-purple-800 uppercase tracking-wider">Score</th>
                                            <th className="px-6 py-3 text-right text-xs font-medium text-purple-800 uppercase tracking-wider">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {subjectLines[activeCategory].map((line, index) => (
                                            <motion.tr
                                                key={index}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.3, delay: index * 0.1 }}
                                                className="hover:bg-purple-50 transition-colors duration-200"
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{line.title}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                                                    <Badge variant="success">{line.score}%</Badge>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger asChild>
                                                                <Button variant="ghost" className="text-purple-600 hover:text-purple-800 transition-colors duration-200">
                                                                    <Copy className="h-4 w-4" />
                                                                </Button>
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p>Copy subject line</p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                </td>
                                            </motion.tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardContent>
                    </Card>
                </motion.div>



                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8"
                >
                    {[
                        { title: "35%", description: "of customers open emails based solely on the subject line." },
                        { title: "69%", description: "of customers mark an email as spam based on the subject line." },
                        { title: "59%", description: "of customers say marketing emails influence their purchase decisions." }
                    ].map((stat, index) => (
                        <Card key={index} className="shadow-lg rounded-lg p-4 bg-white/80 backdrop-blur-sm hover:shadow-xl transition-shadow duration-200">
                            <CardContent className="text-center">
                                <h3 className="text-4xl font-bold text-purple-600 mb-2">{stat.title}</h3>
                                <p className="text-gray-600">{stat.description}</p>
                            </CardContent>
                        </Card>
                    ))}
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}

                >
                    <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4 bg-white rounded-lg shadow-md overflow-hidden">

                        <Card>
                            <CardHeader>
                                <CardTitle>Email Performance Metrics</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={emailStats}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <RechartsTooltip />
                                        <Legend />
                                        <Bar dataKey="value" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle>Industry Benchmarks</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={industryBenchmarks}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="industry" />
                                        <YAxis />
                                        <RechartsTooltip />
                                        <Legend />
                                        <Bar dataKey="openRate" fill="#8884d8" name="Open Rate" />
                                        <Bar dataKey="clickRate" fill="#82ca9d" name="Click Rate" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </div>
                </motion.div>
            </div>
        </main>
    )
}