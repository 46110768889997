// src/components/PreviewPage.jsx

import React, { useState, useEffect } from 'react';
import { MdStarRate } from 'react-icons/md';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { FiMove } from 'react-icons/fi';

const PreviewPage = ({ survey }) => {
  const [responses, setResponses] = useState({});
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const handleChange = (name, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('User Responses:', responses);
    alert('Survey submitted successfully!');
  };

  const evaluateVisibility = (visibleIf) => {
    if (!visibleIf) return true;

    try {
      // Replace {questionName} with the actual response value
      const condition = visibleIf.replace(/\{([^}]+)\}/g, (match, questionName) => {
        const response = responses[questionName];
        return typeof response === 'string' ? `'${response}'` : response;
      });

      // Evaluate the condition
      return eval(condition);
    } catch (error) {
      console.error('Error evaluating visibility condition:', error);
      return true; // Show the question if there's an error in evaluation
    }
  };

  const renderQuestion = (question, index) => {
    if (!evaluateVisibility(question.visibleIf)) {
      return null;
    }

    switch (question.type) {
      case 'text':
      case 'comment':
        return (
          <input
            type={question.type === 'text' ? 'text' : 'textarea'}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-purple-500"
            value={responses[question.name] || ''}
            onChange={(e) => handleChange(question.name, e.target.value)}
            required={question.isRequired}
          />
        );
      case 'dropdown':
        return (
          <select
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-purple-500"
            required={question.isRequired}
            value={responses[question.name] || ''}
            onChange={(e) => handleChange(question.name, e.target.value)}
          >
            <option value="">Select an option</option>
            {question.choices.map((choice, idx) => (
              <option key={idx} value={choice.value}>{choice.text}</option>
            ))}
          </select>
        );
      case 'tagbox':
        return (
          <Select
            isMulti
            options={question.choices.map(choice => ({ value: choice.value, label: choice.text }))}
            value={(responses[question.name] || []).map(value => ({ value, label: question.choices.find(c => c.value === value)?.text }))}
            onChange={(selectedOptions) => handleChange(question.name, selectedOptions.map(option => option.value))}
            className="w-full"
            classNamePrefix="react-select"
            required={question.isRequired}
          />
        );
      case 'checkbox':
      case 'radiogroup':
        return (
          <div className="space-y-2">
            {question.choices.map((choice, idx) => (
              <label key={idx} className="flex items-center">
                <input
                  type={question.type === 'checkbox' ? 'checkbox' : 'radio'}
                  className={`form-${question.type === 'checkbox' ? 'checkbox' : 'radio'} text-purple-600`}
                  name={question.name}
                  value={choice.value}
                  checked={(responses[question.name] || []).includes(choice.value)}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (question.type === 'checkbox') {
                      const currentValues = responses[question.name] || [];
                      const newValues = e.target.checked
                        ? [...currentValues, value]
                        : currentValues.filter(v => v !== value);
                      handleChange(question.name, newValues);
                    } else {
                      handleChange(question.name, value);
                    }
                  }}
                  required={question.isRequired}
                />
                <span className="ml-2">{choice.text}</span>
              </label>
            ))}
          </div>
        );
      case 'boolean':
        return (
          <div className="space-x-4">
            {['Yes', 'No'].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-purple-600"
                  name={question.name}
                  value={option.toLowerCase()}
                  checked={responses[question.name] === option.toLowerCase()}
                  onChange={(e) => handleChange(question.name, e.target.value)}
                  required={question.isRequired}
                />
                <span className="ml-2">{option}</span>
              </label>
            ))}
          </div>
        );
      case 'rating':
        return (
          <div className="flex items-center space-x-2">
            {[...Array(question.rateMax || 5)].map((_, i) => (
              <button
                key={i}
                type="button"
                className={`w-8 h-8 rounded-full ${responses[question.name] === i + 1
                  ? 'bg-purple-600 text-white'
                  : 'bg-white border border-gray-300 text-gray-700'
                  } font-medium hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-purple-500`}
                onClick={() => handleChange(question.name, i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
        );
      case 'ranking':
        return (
          <DragDropContext onDragEnd={(result) => {
            if (!result.destination) return;
            const items = Array.from(responses[question.name] || question.choices);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            handleChange(question.name, items);
          }}>
            <Droppable droppableId={question.name}>
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
                  {(responses[question.name] || question.choices).map((choice, index) => (
                    <Draggable key={choice.value} draggableId={choice.value} index={index}>
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="p-2 bg-white border border-gray-300 rounded-md flex items-center"
                        >
                          <span {...provided.dragHandleProps} className="mr-2 cursor-move">
                            <FiMove className="text-gray-400" />
                          </span>
                          <span className="mr-2">{index + 1}.</span>
                          {choice.text}
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        );
      default:
        return <p className="text-red-500">Unsupported question type: {question.type}</p>;
    }
  };

  return (
    <div className="pt-8 bg-gray-100 text-gray-900 w-full">
      <div className="bg-white shadow-lg rounded-lg p-8 mx-auto">
        <h1 className="text-3xl font-bold mb-4 text-purple-600">{survey.title}</h1>
        <p className="text-gray-600 mb-8">{survey.description}</p>

        <form onSubmit={handleSubmit}>
          {survey.elements.map((question, index) => (
            <div key={question.name} className="mb-8 p-6 bg-gray-50 rounded-lg shadow">
              <label className="block text-lg font-semibold mb-2 text-gray-800">
                {index + 1}. {question.title}
                {(question.isRequired == 1) && <span className="text-red-500 ml-1">*</span>}
              </label>
              {renderQuestion(question, index)}
            </div>
          ))}

          <div className="mt-8">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-purple-600"
                checked={privacyPolicyAccepted}
                onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
                required
              />
              <span className="ml-2 text-sm text-gray-700">
                I agree to the <a href="#" className="text-purple-600 hover:underline">Privacy Policy</a>
              </span>
            </label>
          </div>

          <div className="mt-4 flex justify-center">
            <button
              type="submit"
              className="bg-purple-600 text-white px-6 py-3 rounded-lg shadow hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition w-full sm:w-1/2 md:w-1/3"
              disabled={!privacyPolicyAccepted}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PreviewPage;