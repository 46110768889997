import React, { useState, useEffect } from 'react';
import { FiImage } from 'react-icons/fi';
import { motion } from 'framer-motion';

const SurveyHeader = ({
  surveyTitle,
  surveyDescription,
  surveyLogo,
  onSurveyUpdate,
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  useEffect(() => {
    if (surveyLogo) {
      setImagePreview(surveyLogo);
    } else {
      setImagePreview(null);
    }
  }, [surveyLogo]);

  const handleSurveyTitleChange = (e) => {
    onSurveyUpdate({ title: e.target.value });
  };

  const handleSurveyDescriptionChange = (e) => {
    onSurveyUpdate({ description: e.target.value });
  };

  const handleSurveyImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        onSurveyUpdate({
          logo: base64String
        });
        setImagePreview(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white border border-gray-200 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
    >
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div className="flex-grow mr-4">
          <input
            type="text"
            className="text-3xl sm:text-4xl font-bold bg-transparent text-purple-600 w-full focus:outline-none mb-2 sm:mb-0 transition-all duration-200"
            placeholder="Your Survey Title"
            value={surveyTitle}
            onChange={handleSurveyTitleChange}
            aria-label="Survey Title"
          />
          <textarea
            className="mt-2 w-full bg-transparent text-gray-600 focus:outline-none resize-none transition-all duration-200"
            rows="2"
            placeholder="Your Survey Description"
            value={surveyDescription}
            onChange={handleSurveyDescriptionChange}
            aria-label="Survey Description"
          />
        </div>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="flex items-center justify-center sm:justify-end mt-4 sm:mt-0"
        >
          <label
            htmlFor="survey-image-upload"
            className="cursor-pointer bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300"
            title="Upload Survey Image"
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Survey Logo"
                className="w-24 h-24 sm:w-32 sm:h-32 object-contain"
              />
            ) : (
              <div className="w-24 h-24 sm:w-32 sm:h-32 flex items-center justify-center border border-gray-300 rounded-lg bg-gray-50">
                <FiImage size={32} className="text-gray-400" />
              </div>
            )}
          </label>
          <input
            type="file"
            id="survey-image-upload"
            accept="image/*"
            onChange={handleSurveyImageUpload}
            className="hidden"
          />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default SurveyHeader;
