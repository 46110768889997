'use client'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Avatar, AvatarFallback } from "../components1/ui/avatar";
import { Button } from "../components1/ui/button"
import { Card, CardContent } from "../components1/ui/card"
import { Textarea } from "../components1/ui/textarea"
import { Switch } from "../components1/ui/switch"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components1/ui/dropdown-menu"
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../components1/ui/sheet"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components1/ui/tooltip"
import {
  ChevronDown,
  History,
  Paperclip,
  Send,
  X,
  Copy,
  Share,
  FileText,
  Download,
  Clock,
  Mail,
  Instagram,
  Search,
  Users,
  Rocket,
  Sun,
  Moon,
  Sparkles,
  HelpCircle,
} from 'lucide-react';
import { sendMsgToOpenAI } from '../Utils/chatGPT';
import AnimatedText from '../Components/AnimatedText';
import { useNavigate } from 'react-router-dom';

const marketingQueries = [
  "How can I create an effective marketing survey?",
  "What are the best strategies for understanding my target audience?",
  "Can you help me write high-conversion rate email content?",
  "What are some effective ways to segment my email list?"
];

const promptOptions = [
  "Optimize for conversions",
  "Make it more engaging",
  "Add data-driven insights",
  "Simplify the language",
  "Include a call-to-action"
];

const historyItems = [
  { title: "Email Campaign Strategy", time: "2 hours ago", icon: "mail" },
  { title: "Social Media Content Plan", time: "Yesterday", icon: "instagram" },
  { title: "SEO Optimization Tips", time: "2 days ago", icon: "search" },
  { title: "Customer Segmentation Analysis", time: "1 week ago", icon: "users" },
  { title: "Product Launch Marketing", time: "2 weeks ago", icon: "rocket" },
];

export function AiMarketingChat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [showDemoQuestions, setShowDemoQuestions] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isAIEnhanced, setIsAIEnhanced] = useState(false);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    document.body.classList.toggle('dark', isDarkMode);
  }, [isDarkMode]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments(prev => [...prev, ...files]);
  };

  const removeAttachment = (index) => {
    setAttachments(prev => prev.filter((_, i) => i !== index));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const shareResponse = (text) => {
    // Implement sharing functionality
    console.log("Sharing:", text);
  };

  const exportToPDF = (text) => {
    // Implement PDF export
    console.log("Exporting to PDF:", text);
  };

  const exportWholeChat = () => {
    // Implement chat export
    console.log("Exporting whole chat");
  };

  const initialContext = {
    role: "system",
    content: `You are a specialized assistant for Relevant Reach, focused on guiding users in creating surveys to understand their target audience. Your responses should remain within the domain of Marketing and Relevant Reach-related queries. Start by offering help and proceed based on the user's familiarity with their target audience.

    Conversation Structure:
    1. Initial Offer: Start with "Do you need help with creating your survey to better understand your target audience?"
    2. Audience Familiarity:
       - If they know their target audience, ask: "What additional information would you like to gather about your audience, such as hobbies, interests, or income?"
       - If they don't know their target audience, offer: "Would you like me to suggest basic survey questions to help identify your target audience, or would you prefer to book a time for a Reach Out session using your credits?"
    3. Survey Assistance: Offer to assist in forming specific survey questions based on the information provided about their target audience.
    4. Next Steps: Always provide a clear next step based on their response, such as guiding them to book a session or suggesting survey improvements.

    General Queries:
    - Purpose of Credits: "Credits are used for reach out sessions. You can purchase more credits as needed."
    - Survey Answer Visibility: "To see survey answers, you need to create answer options in your survey. If you need help with this, book a time with us for 1 credit!"
    - Starting a Survey: "To start a survey:
       1. Click 'Create Survey'.
       2. Choose your survey type (e.g., multiple choice, rating scale, open text).
       3. Add questions and answer options.
       4. Customize design and branding.
       5. Preview and launch your survey."
    - Canceling Subscription: "Cancel your subscription in the Subscription section. Detailed steps are available there."
    - Common Survey Questions:
       - If they know their audience: "Great! What more would you like to know about them?"
       - If unsure: "Who do you think your current audience might be?" Adjust further questions based on their answer.

    - Managing Reach Out Sessions:
       - If user has credits: "You can use your Reach Out 'Live' 1:1 Session as part of your subscription plan. Would you like to schedule a call?
       - If user has already used their session: "You have already used your session for this month. You can upgrade to the Impact plan to get up to 3 Reach Out sessions per month, or choose a pay-as-you-go option. Would you like to upgrade or pay as you go?" Provide the relevant links: [UPGRADE_LINK] or [PAY_AS_YOU_GO_LINK].

    Upgrade to Amplify (for Connect & Engage Plan Users):
    - If survey responses exceed 10 while on the dashboard:
      - Offer: "Would you like content suggestions tailored to your audience using your dashboard data?"
        - If they agree and have the Connect & Engage plan: "You are on Connect & Engage. Would you like to upgrade for a 7-day free trial?"
        - If on Amplify: "Let me analyze your dashboard data to suggest content targeting one of your audience segments."
        - If they decline: Offer to show an example of what the content might look like and proceed based on their product or service.
    Create 1 blog article (as an example) that incorporates the top SEO terms around their dashboard data with the following:
    - 1 audience.
    - 1 income level.
    - 1 hobby.
    - 1 interest.
    - 1 product or service it is interested in.

    Note: Return a key with the message to show buttons if needed. Use the user's current plan details and the current session context for personalization.`
  };

  const chatId = Math.random().toString(36).substring(2, 15);

  const handleSend = async (e) => {
    e.preventDefault();
    if (!input.trim() && attachments.length === 0) return;

    const userMessage = { role: 'user', content: input, attachments };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setAttachments([]);
    setIsTyping(true);
    setShowDemoQuestions(false);

    try {
      const enhancedPrompt = isAIEnhanced ? `${initialContext.content}\n\nEnhanced AI Mode: On. Please provide more detailed and data-driven responses.` : initialContext.content;
      const res = await sendMsgToOpenAI(enhancedPrompt, input, chatId);
      const aiMessage = { role: 'ai', content: res };
      setMessages(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error("Error in handleSend:", error);
    } finally {
      setIsTyping(false);
    }
  };

  const renderIcon = (iconName) => {
    switch (iconName) {
      case 'mail': return <Mail className="w-4 h-4 text-purple-700" />;
      case 'instagram': return <Instagram className="w-4 h-4 text-purple-700" />;
      case 'search': return <Search className="w-4 h-4 text-purple-700" />;
      case 'users': return <Users className="w-4 h-4 text-purple-700" />;
      case 'rocket': return <Rocket className="w-4 h-4 text-purple-700" />;
      default: return null;
    }
  };

  return (
    <div className={`flex flex-col h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gradient-to-br from-purple-100 via-pink-100 to-indigo-100'}`}>
      <header className={`${isDarkMode ? 'bg-gray-800' : 'bg-gradient-to-r from-purple-600 to-indigo-600'} shadow-md p-4 flex justify-between items-center`}>
        {/* <img onClick={() => navigate(-1)} src="/lightlogo.png" alt="Logo" className="h-12 w-auto cursor-pointer" /> */}
        <h1 className="text-2xl md:text-3xl font-bold text-white">RR Marketing Assistant</h1>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            size="sm"
            className={`${isDarkMode ? 'text-white border-white hover:bg-gray-700' : 'text-white border-white hover:bg-white hover:text-purple-700'}`}
            onClick={exportWholeChat}>
            <Download className="w-4 h-4 mr-1" />
            <span className="hidden sm:inline">Export Chat</span>
          </Button>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Switch
                  checked={isDarkMode}
                  onCheckedChange={setIsDarkMode}
                  className="ml-2"
                />
              </TooltipTrigger>
              <TooltipContent>
                <p>Toggle Dark Mode</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </header>
      <div className="flex-1 overflow-auto p-6">
        {showDemoQuestions && (
          <div className="flex flex-col items-center justify-center h-full space-y-4">
            <h2 className={`text-2xl font-semibold ${isDarkMode ? 'text-purple-300' : 'text-purple-800'} mb-4`}>How can I assist with your marketing needs?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-2xl w-full">
              {marketingQueries.map((query, index) => (
                <Card
                  key={index}
                  className={`${isDarkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white bg-opacity-70 hover:bg-opacity-100'} transition-all duration-300 shadow-md hover:shadow-lg border-purple-200`}>
                  <CardContent className="p-4">
                    <Button
                      variant="ghost"
                      className={`w-full h-full text-left ${isDarkMode ? 'text-purple-300 hover:text-purple-100' : 'text-purple-700 hover:text-purple-900'} whitespace-normal`}
                      onClick={() => {
                        setInput(query);
                        setShowDemoQuestions(false);
                      }}>
                      {query}
                    </Button>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        )}
        <div className="space-y-6">
          <AnimatePresence>
            {messages.map((message, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                <div className={`flex items-start gap-3 max-w-[80%] ${message.role === 'user' ? 'flex-row-reverse' : ''}`}>
                  <Avatar className={`w-10 h-10 border-2 ${isDarkMode ? 'border-purple-500' : 'border-purple-300'}`}>
                    <AvatarFallback>{message.role === 'user' ? 'U' : 'AI'}</AvatarFallback>
                  </Avatar>
                  <div className={`rounded-2xl p-4 ${message.role === 'user' ? (isDarkMode ? 'bg-purple-800 text-white' : 'bg-gradient-to-r from-purple-500 to-indigo-500 text-white') : (isDarkMode ? 'bg-gray-700 border border-gray-600' : 'bg-white bg-opacity-80 border border-purple-200')}`}>
                    <AnimatedText text={message.content} className={message.role === 'user' ? 'text-white' : ''} />
                    {message.attachments && message.attachments.length > 0 && (
                      <div className="mt-2 space-y-1">
                        {message.attachments.map((file, i) => (
                          <div key={i} className={`text-sm ${isDarkMode ? 'text-purple-300' : 'text-purple-200'}`}>
                            📎 {file.name}
                          </div>
                        ))}
                      </div>
                    )}
                    {message.role === 'ai' && (
                      <div className="mt-2 flex justify-end space-x-2">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => copyToClipboard(message.content)}>
                                <Copy className="w-4 h-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Copy to clipboard</p>
                            </TooltipContent>
                          </Tooltip>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button variant="ghost" size="sm" onClick={() => shareResponse(message.content)}>
                                <Share className="w-4 h-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Share response</p>
                            </TooltipContent>
                          </Tooltip>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button variant="ghost" size="sm" onClick={() => exportToPDF(message.content)}>
                                <FileText className="w-4 h-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Export to PDF</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
          {isTyping && (
            <div className="flex justify-start">
              <div className={`${isDarkMode ? 'bg-gray-700 text-purple-300' : 'bg-white bg-opacity-80 text-purple-700'} rounded-full px-4 py-2`}>
                <span className="animate-pulse">AI is analyzing...</span>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <footer className={`${isDarkMode ? 'bg-gray-800' : 'bg-white bg-opacity-90'} p-6 shadow-lg`}>
        <form onSubmit={handleSend} className="space-y-4">
          {attachments.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {attachments.map((file, index) => (
                <div
                  key={index}
                  className={`${isDarkMode ? 'bg-gray-700 text-purple-300' : 'bg-purple-100 text-purple-700'} rounded-full px-3 py-1 text-sm flex items-center`}>
                  <span className="mr-2">{file.name}</span>
                  <button
                    type="button"
                    onClick={() => removeAttachment(index)}
                    className={`${isDarkMode ? 'text-purple-300 hover:text-purple-100' : 'text-purple-500 hover:text-purple-700'}`}>
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className="flex items-end gap-2">
            <div className="relative flex-1">
              <Textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Ask about marketing strategies, surveys, or content..."
                className={`pr-10 resize-none border-2 ${isDarkMode ? 'border-purple-700 focus:border-purple-500 bg-gray-700 text-white' : 'border-purple-200 focus:border-purple-500'} rounded-2xl`}
                rows={1} />
              <Button
                type="button"
                size="icon"
                variant="ghost"
                className={`absolute right-2 bottom-2 ${isDarkMode ? 'text-purple-300 hover:text-purple-100' : 'text-purple-500 hover:text-purple-700'}`}
                onClick={() => fileInputRef.current.click()}>
                <Paperclip className="w-5 h-5" />
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple
                className="hidden" />
            </div>
            <Button
              type="submit"
              size="icon"
              className={`${isDarkMode ? 'bg-purple-700 hover:bg-purple-600' : 'bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700'} text-white rounded-full w-12 h-12`}>
              <Send className="w-5 h-5" />
            </Button>
          </div>
        </form>
        <div className="flex justify-between mt-4">
          <Sheet open={isHistoryOpen} onOpenChange={setIsHistoryOpen}>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className={`${isDarkMode ? 'text-purple-300 border-purple-700 hover:bg-purple-900' : 'text-purple-700 border-purple-300 hover:bg-purple-100'}`}>
                <History className="w-4 h-4 mr-1" />
                <span>History</span>
              </Button>
            </SheetTrigger>
            <SheetContent
              side="left"
              className={`${isDarkMode ? 'bg-gray-800 border-r-2 border-purple-700' : 'bg-gradient-to-b from-purple-100 to-indigo-100 border-r-2 border-purple-200'}`}>
              <SheetHeader>
                <SheetTitle className={`${isDarkMode ? 'text-purple-300' : 'text-purple-700'}`}>Chat History</SheetTitle>
              </SheetHeader>
              <div className="mt-4 space-y-4">
                {historyItems.map((item, index) => (
                  <div
                    key={index}
                    className={`flex items-center space-x-3 p-2 rounded-lg ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-white hover:bg-opacity-50'} transition-colors duration-200`}>
                    <div className={`${isDarkMode ? 'bg-purple-900' : 'bg-purple-200'} rounded-full p-2`}>
                      {renderIcon(item.icon)}
                    </div>
                    <div className="flex-1">
                      <h3 className={`font-medium text-sm ${isDarkMode ? 'text-purple-300' : 'text-purple-800'}`}>{item.title}</h3>
                      <p className={`text-sm ${isDarkMode ? 'text-purple-400' : 'text-purple-600'} flex items-center`}>
                        <Clock className="w-3 h-3 mr-1" />
                        {item.time}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </SheetContent>
          </Sheet>

          <Button
            variant="outline"
            size="sm"
            className={`${isDarkMode ? 'text-purple-300 border-purple-700 hover:bg-purple-900' : 'text-purple-700 border-purple-300 hover:bg-purple-100'}`}
            onClick={() => {
              setMessages([]);
              setShowDemoQuestions(true);
            }}
          >
            <span>+ New chat</span>
          </Button>
        </div>

      </footer>
    </div>
  );
}